import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/home.js";

import DashSideNavbar from "components/DashSideNavbar.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Achiever(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksAfterlogin />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader dash_bg_odd"}>
        <div className="container">
          <GridContainer>
            <GridItem sm={12} md={12} lg={3}>
              <DashSideNavbar />
            </GridItem>
            <GridItem sm={12} md={12} lg={9}>
              <div className="dash_right_setion">
                <div className="dash_box">
                  <h2 className="inner_title">Achiever</h2>
                  <div className="primary_table_div">
                    <div className="table-responsive">
                      <table className="table primary_table">
                        <thead>
                          <tr>
                            <th>Achievers</th>
                            <th>Target($)</th>
                            <th>Awards</th>
                            <th>Destination</th>
                            <th>Gift</th>
                            <th>Levels</th>
                            <th>Ratio</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="yellow_txt">Star</td>
                            <td>30,000</td>
                            <td>2 Days - 3 Nights</td>
                            <td>Bangkok</td>
                            <td>NA</td>
                            <td>5</td>
                            <td>60 / 40</td>
                          </tr>
                          <tr>
                            <td className="yellow_txt">Royal</td>
                            <td>75,000</td>
                            <td>2 Days - 3 Nights</td>
                            <td>Bali (Indonesia)</td>
                            <td>Venue Car</td>
                            <td>7</td>
                            <td>60 / 40</td>
                          </tr>
                          <tr>
                            <td className="yellow_txt">Ellite</td>
                            <td>1,50,000</td>
                            <td>2 Days - 3 Nights</td>
                            <td>Baku (Azerbaijan)</td>
                            <td>Jeep</td>
                            <td>9</td>
                            <td>60 / 20 / 20</td>
                          </tr>
                          <tr>
                            <td className="yellow_txt">Marvel</td>
                            <td>6,50,000</td>
                            <td>2 Days - 3 Nights</td>
                            <td>Tashkent (Uzbekistan)</td>
                            <td>BMW x1</td>
                            <td>15</td>
                            <td>40 / 20 / 20 / 20</td>
                          </tr>
                          <tr>
                            <td className="yellow_txt">Ambassador</td>
                            <td>12,80,000</td>
                            <td>4 Days - 5 Nights</td>
                            <td>Lasvegas</td>
                            <td>Range Rover</td>
                            <td>20</td>
                            <td>40 / 20 / 20 / 20</td>
                          </tr>
                          <tr>
                            <td className="yellow_txt">Crown Diamond</td>
                            <td>30,00,000</td>
                            <td>2 Week with Travel Time</td>
                            <td>World Tour</td>
                            <td>Rolls Royce</td>
                            <td>20</td>
                            <td>1 Ambassador in a Leg Total 3</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}