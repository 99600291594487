import { isAddress, isBEP20Address } from "../tronWeb/tronWeb"

export async function profileValidate(data) {

    var errors = {};

    var email = data.email;
    var address = data.address;
    var bep20address = data.bep20address;
    var name = data.name;

    var isValid = await isAddress(address);
    var isValid1 = await isBEP20Address(bep20address);

    const regx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var validEmail = regx.test(String(email).toLowerCase());

    if (name === "") {
        errors.name = "Please enter name";
    }

    if (address === "") {
        errors.address = "Please enter address";
    } else if (!isValid) {
        errors.address = "Please enter valid address";
    }

    // if (bep20address === "") {
    //     errors.bep20address = "Please enter address";
    // } else if (!isValid1) {
    //     errors.bep20address = "Please enter valid address";
    // }

    if (email === "") {
        errors.email = "Please enter email id";
    } else if (!validEmail) {
        errors.email = "Please enter valid email id";
    }

    return errors;
}

export async function changePwdValidate(data) {

    var errors = {};

    var oldpassword = data.oldpassword;
    var newpassword = data.newpassword;
    var cpassword = data.cpassword;

    if (oldpassword === "") {
        errors.oldpassword = "Please enter the old password";
    }

    if (newpassword === "") {
        errors.newpassword = "Please enter password";
    } else if (newpassword.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d@$.!%*#?&]/) < 0) {
        errors.newpassword = "Your password must contain small and caps letters, special character and number.";
    }

    if (cpassword === "") {
        errors.cpassword = "Please enter confirm password";
    } else if (newpassword !== cpassword) {
        errors.cpassword = "Passwords must match";
    }

    return errors;
}
