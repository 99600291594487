export function packageAmount() {

    var levelList = [];
    if (process.env.NODE_ENV === "production") {
        levelList = [
            { 'level': 1, 'amount': 150 },
            { 'level': 2, 'amount': 300 },
            { 'level': 3, 'amount': 500 },
            { 'level': 4, 'amount': 1000 },
            { 'level': 5, 'amount': 3000 },
            { 'level': 6, 'amount': 5000 },
            { 'level': 7, 'amount': 10000 },
            { 'level': 8, 'amount': 20000 }
        ];
    } else {
        levelList = [
            { 'level': 1, 'amount': 150 },
            { 'level': 2, 'amount': 300 },
            { 'level': 3, 'amount': 500 },
            { 'level': 4, 'amount': 1000 },
            { 'level': 5, 'amount': 3000 },
            { 'level': 6, 'amount': 5000 },
            { 'level': 7, 'amount': 10000 },
            { 'level': 8, 'amount': 20000 }
        ];

    }

    return levelList;

}