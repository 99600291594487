import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import ReactLoading from "react-loading";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/home.js";
import { getProfile } from "../Api/UserActions"
import DashSideNavbar from "components/DashSideNavbar.js";
import GameonModal from "components/Modal/GameonModal";
import config from "../config/config"
import $ from "jquery";

import { updateGameon } from "../Api/UserActions"

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
var clickGameon = true;

export default function Gameon(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const userData = useSelector((state) => state.authReducer);
  const [gameoncount, setgameoncount] = useState(0);
  const [isLoad, setisLoad] = useState(false);

  useEffect(() => {
    loadScript();
    setTimeout(function () {
      getProfileData();
    }, 2000)

  }, [userData]);

  async function getProfileData() {

    if (userData && userData.id && userData.id !== "") {
      var { result } = await getProfile({ id: userData.id });
      setgameoncount(result && result.gameoncount ? result.gameoncount : 0)
      $('#gameCnt').val(result.gameoncount);
      $('#chance').html(result.gameoncount);
    }
  }


  function loadScript() {
    var cube = document.getElementById('cube');

    var min = 1;
    var max = 24;

    cube.onclick = async function () {

      var count = $('#gameCnt').val();

      if (count && count > 0 && clickGameon) {
        clickGameon = false
        var xRand = getRandom(max, min);
        var yRand = getRandom(max, min);

        var result = await getResult(xRand, yRand);
        setTimeout(async function () {
          setisLoad(true)
        }, 5000)
        cube.style.webkitTransform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)';
        cube.style.transform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)';

        setTimeout(async function () {
          window.$('#Congratulations').modal('show');
          var amt = 20 * result;
          //amt = amt * 0.05;
          $('#winamt').html(amt);
          await updateGameon({ amount: amt });
          var count = $('#gameCnt').val();
          var reduceCnt = count - 1;
          if (reduceCnt < 0) {
            reduceCnt = 0;
          }
          $('#gameCnt').val(reduceCnt);
          $('#chance').html(reduceCnt);
          clickGameon = true;
          setisLoad(false)
        }, 10000)


      }

    }

    function getRandom(max, min) {
      return (Math.floor(Math.random() * (max - min)) + min) * 90;
    }

  }

  function mod(n, m) {
    return ((n % m) + m) % m;
  }

  async function getResult(rotX, rotY) {
    let countX = await mod(rotX / 90, 4);
    if (countX === 1) {
      return 6;
    }
    if (countX === 3) {
      return 5;
    }
    let countY = mod(rotY / 90 + countX, 4);
    return [1, 4, 2, 3][countY];
  }

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksAfterlogin />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader gameonbg"}>
        <div className="container">
          <GridContainer>
            <GridItem sm={12} md={12} lg={3}>
              <DashSideNavbar />
            </GridItem>
            <GridItem sm={12} md={12} lg={9}>
              <div className="dash_right_setion">
                <div className="dash_box">
                  <input type="hidden" id="gameCnt" />
                  <h2 className="inner_title">Gameon</h2>
                  <p>If you get same number like below dice you will get reward.</p>
                  <h3>Gameon chance: <span id="chance">0</span></h3>

                  {isLoad &&
                    <div class="form-group button_new_home banner">
                      <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading stakingloader" />
                    </div>
                  }

                  <div className="dice_roll_panel">
                    <div className="dice_roll_panel_left">
                      <h2>ROLL ON DICE</h2>
                      <h3>You win exclusive rewards</h3>
                    </div>
                    <div className="dice_roll_panel_right">
                      <div class="cube_container">
                        <div id="cube">
                          <div class="front">
                            <span class="dot dot1"></span>
                          </div>
                          <div class="back">
                            <span class="dot dot1"></span>
                            <span class="dot dot2"></span>
                          </div>
                          <div class="right">
                            <span class="dot dot1"></span>
                            <span class="dot dot2"></span>
                            <span class="dot dot3"></span>
                          </div>
                          <div class="left">
                            <span class="dot dot1"></span>
                            <span class="dot dot2"></span>
                            <span class="dot dot3"></span>
                            <span class="dot dot4"></span>
                          </div>
                          <div class="top">
                            <span class="dot dot1"></span>
                            <span class="dot dot2"></span>
                            <span class="dot dot3"></span>
                            <span class="dot dot4"></span>
                            <span class="dot dot5"></span>
                          </div>
                          <div class="bottom">
                            <span class="dot dot1"></span>
                            <span class="dot dot2"></span>
                            <span class="dot dot3"></span>
                            <span class="dot dot4"></span>
                            <span class="dot dot5"></span>
                            <span class="dot dot6"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <GameonModal />
        </div>
      </div>
      <Footer />
    </div>
  );
}