/*eslint-disable*/
import React, { useEffect } from "react";
// react components for routing our app without refresh
import { Link, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Hidden } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { getAuth } from '../../helper/getAuth';
import { AUTH_USER } from '../../constant/index'

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const userData = useSelector((state) => state.authReducer);

  useEffect(() => {
    checkAuth();
  }, []);

  async function checkAuth() {
    var token = localStorage.getItem('accessToken');
    var response = await getAuth(token);
    dispatch({
      type: AUTH_USER,
      payload: response
    });
  }

  async function logout() {
    var data = {
      name: "",
      id: "",
      isAuth: false
    }
    localStorage.removeItem('accessToken');
    dispatch({
      type: AUTH_USER,
      payload: data
    });
    history.push('/login');
  }

  const classes = useStyles();
  return (
    <div className="home_page_menu">
      <List className={classes.list + " main_navbar"}>
        <ListItem className={classes.listItem}>
          <div className="profile_menu">
            <div>
              <h4>{(userData && userData.name) ? userData.name : ""}</h4>
              <Link to="/profile" color="transparent" className="nav-link">View Profile</Link>
            </div>
            <img src={require("../../assets/images/profile_icon.png")} alt="Profile" />
          </div>
        </ListItem>
        <Hidden lgUp>
          <ListItem className={classes.listItem}>
            <Link to="/dashboard" color="transparent" className="nav-link">Dashboard</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/my-network" color="transparent" className="nav-link">My Network</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/my-earning" color="transparent" className="nav-link">My Earning</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/staking" color="transparent" className="nav-link">Staking</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/gameon" color="transparent" className="nav-link">Gameon</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/withdraw" color="transparent" className="nav-link">Withdraw</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/support-ticket" color="transparent" className="nav-link">Support Ticket</Link>
          </ListItem>
          {/* <ListItem className={classes.listItem}>
            <Link to="/news" color="transparent" className="nav-link">News & Update</Link>
          </ListItem> */}
          <ListItem className={classes.listItem}>
            <Link style={{ 'cursor': 'pointer' }} onClick={() => { logout() }} color="transparent" className="nav-link">Logout</Link>
          </ListItem>
        </Hidden>
      </List>
    </div>
  );
}
