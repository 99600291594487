import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link, useHistory } from "react-router-dom";

import isEmpty from 'is-empty';
import ReactLoading from "react-loading";
import { packageAmount } from "../../helper/packages"
import config from '../../config/config';
import { registerValidate } from './validation';
import { saveUser, checkRef } from '../../Api/UserActions';
import { toastAlert } from '../../helper/toastAlert';
import SuccessModal from './SuccessModal';
import { connection } from "../../helper/connection";

const useStyles = makeStyles(styles);

var initialData = {
  "referalId": "",
  "crazyname": "",
  "dappaddress": "",
  "emailid": "",
  "password": "",
  "confirmpassword": ""
}

export default function Login(props) {

  const classes = useStyles();
  const history = useHistory();

  window.addEventListener('message', function (e) {
    try {
      if (e.data.message && e.data.message.action === "setAccount") {
        var loginName = e.data.message.data.name;
        var getName = localStorage.getItem('qas4567d');
        var encodedString = window.btoa(loginName);
        var decodedString = window.atob(getName);

        if (getName && getName !== null) {
          if (decodedString !== loginName) {
            setTimeout(function () {
              var hash = window.location.hash.substr(1);
              getAddress(hash);
            }, 1000);
          }
        } else {
          localStorage.setItem('qas4567d', encodedString);
        }
      }
    } catch (e) {
      console.log('wallet error', e)
    }
  });

  const [fromValue, setfromValue] = useState(initialData);
  const [errors, seterrors] = useState({});
  const [isLoad, setisLoad] = useState(false);

  useEffect(() => {

    setTimeout(function () {
      var hash = window.location.hash.substr(1);
      getAddress(hash);
    }, 1500);

  }, []);

  async function getAddress(hash) {
    var get = await connection();
    if (get && get.tronWeb && get.tronWeb !== "" && get.address) {
      var address = get.address;
      var formData = {};
      if (hash && hash !== "") {
        formData = { ...fromValue, ...{ "dappaddress": address, "referalId": hash } }
      } else {
        formData = { ...fromValue, ...{ "dappaddress": address } }
      }
      setfromValue(formData);
    } else {
      seterrors({ dappaddress: "Please login tronlink" })
    }

  }


  const inputChange = async (event) => {

    var id = event.target.id;
    var value = event.target.value;

    var formData = { ...fromValue, ...{ [id]: value } }
    var isError = await registerValidate(formData);

    seterrors({});
    if (!isEmpty(isError)) {
      seterrors(isError)
    }

    setfromValue(formData);
  }

  async function submitRegister() {

    var isError = await registerValidate(fromValue);

    seterrors({});
    if (!isEmpty(isError)) {
      seterrors(isError)
    } else {
      setisLoad(true);
      var get = await connection();
      if (get && get.tronWeb && get.tronWeb !== "") {

        // if (get.fullHost !== config.fullHost) {
        //   setisLoad(false);
        //   toastAlert('error', "Please login  into tronlink", 'tronlink');
        //   return;
        // }
        try {
          var tronWebConn = get.tronWeb;
          var checkLive = await tronWebConn.contract().at(config.InorbitContract);
        } catch (err) {
          setisLoad(false);
          toastAlert('error', "Please login  into tronlink mainet", 'tronlink');
          return;
        }

        var { ref, user, status } = await checkRef({
          referalId: fromValue.referalId,
          dappaddress: fromValue.dappaddress
        });

        if (ref === "false" || status === 400) {
          toastAlert('error', "Sponsor wallet is not exits", 'register');
          setisLoad(false);
          return false;
        } else if (user === "true") {
          toastAlert('error', "Trc-20 Dapp wallet is exits", 'register');
          setisLoad(false);
          return false;
        }

        var getAmt = await packageAmount();
        var amount = getAmt[0].amount
        var tronWebConn = get.tronWeb;
        var address = get.address;

        var contract = await tronWebConn.contract().at(config.USDT);
        var balanceOf = await contract.balanceOf(address).call();
        var decimals = await contract.decimals().call();

        var inorbitContract = await tronWebConn.contract().at(config.InorbitContract);

        var balance = balanceOf / parseFloat(10 ** decimals);
        var buyAmt = parseFloat(amount) * parseFloat(10 ** decimals);

        if (amount <= balance) {
          try {
            var approveAmt = 1000000 * 10 ** decimals;
            await contract.approve(config.InorbitContract, approveAmt).send({
              feeLimit: 1000000000,
              callValue: approveAmt,
              shouldPollResponse: false
            });

            var result = await inorbitContract.activatePlan(1, buyAmt).send({
              feeLimit: 1000000000,
              callValue: buyAmt,
              shouldPollResponse: false
            });
            var data = {
              pack: 1,
              referalId: fromValue.referalId,
              dappaddress: fromValue.dappaddress,
              txid: result,
              amount: amount
            }

            var { message, errors, token } = await saveUser(data);

            if (!isEmpty(errors)) {
              seterrors(errors)
            } else if (message === "invalidreferal") {
              toastAlert('error', "Sponsor wallet is not exits", 'register');
            } else if (message === "exits") {
              toastAlert('error', "Trc-20 Dapp AddressSponsor wallet is exits", 'register');
            } else if (message === "added") {
              toastAlert('success', "Successfully registered", 'register');
              setfromValue(initialData);
              //window.$('#Congratulations').modal('show');
              localStorage.setItem('accessToken', token);
              localStorage.setItem("Congratulations", "yes")
              history.push('/dashboard');

            } else {
              toastAlert('error', "Oops something went wrong.!", 'register');
            }
            setisLoad(false);
          } catch (err) {
            setisLoad(false);
          }

        } else {
          setisLoad(false);
          toastAlert('error', "Insuffucient balance", 'balance');
        }
      } else {
        setisLoad(false);
        toastAlert('error', "Please login into tronlink", 'tronlink');
      }
    }

  }

  return (
    <div>
      <div className="auth_panel">
        <div id='stars'></div>
        <div id='stars2'></div>
        <div id='stars3'></div>
        <div className={classes.container + " auth_padding_container"}>
          <GridContainer justify="center">
            <GridItem sm={12} md={10} lg={6} className="m-auto">
              <div className="text-center">
                <Link to="/"><img src={require("../../assets/images/logo.png")} alt="logo" className="img-fluid auth_logo" /></Link>
              </div>
              <p className="content_clas_sd">Welcome to Real Metaverse Multi Blockchain Ecosystem</p>
              <form className="auth_form">
                <h1>Register</h1>
                {/* <div className="auth_main_div">
                  <div className="login_top">
                    <h2>The entrance to the office</h2>
                    <Button className="blue_btn"><span>REGISTER</span></Button>
                  </div>
                  <div className="login_bottom">
                    <h2>IF YOU HAVE AN ACCOUNT</h2>
                    <Button className="auth_btn"><span>AUTHORIZATION</span></Button>
                  </div>
                </div> */}
                <div class="form-group">
                  <label for="">Sponsor wallet</label>
                  <input type="text" onChange={inputChange} class="form-control" id="referalId" className="auth_input" placeholder="" value={fromValue.referalId} />
                  {errors && errors.referalId && errors.referalId !== "" &&
                    <p style={{ color: 'red' }}>{errors.referalId}</p>
                  }
                </div>
                {/* <div class="form-group">
                  <label for="">Meta Crazy Name</label>
                  <input type="text" onChange={inputChange} class="form-control" id="crazyname" className="auth_input" placeholder="" value={fromValue.crazyname} />
                  {errors && errors.crazyname && errors.crazyname !== "" &&
                    <p style={{ color: 'red' }}>{errors.crazyname}</p>
                  }
                </div> */}
                <div class="form-group">
                  <label for="">Trc-20 Dapp Address</label>
                  <input type="text" readOnly onChange={inputChange} class="form-control" id="dappaddress" className="auth_input" placeholder="" value={fromValue.dappaddress} />
                  {errors && errors.dappaddress && errors.dappaddress !== "" &&
                    <p style={{ color: 'red' }}>{errors.dappaddress}</p>
                  }
                </div>
                {/* <div class="form-group">
                  <label for="">Email ID</label>
                  <input type="email" onChange={inputChange} class="form-control" id="emailid" className="auth_input" placeholder="" value={fromValue.emailid} />
                  {errors && errors.emailid && errors.emailid !== "" &&
                    <p style={{ color: 'red' }}>{errors.emailid}</p>
                  }
                </div> */}
                {/* <div class="form-group">
                  <label for="">Password</label>
                  <input type="password" onChange={inputChange} class="form-control" id="password" className="auth_input" placeholder="" value={fromValue.password} />
                  {errors && errors.password && errors.password !== "" &&
                    <p style={{ color: 'red' }}>{errors.password}</p>
                  }
                </div>
                <div class="form-group">
                  <label for="">Confirm Password</label>
                  <input type="password" onChange={inputChange} class="form-control" id="confirmpassword" className="auth_input" placeholder="" value={fromValue.confirmpassword} />
                  {errors && errors.confirmpassword && errors.confirmpassword !== "" &&
                    <p style={{ color: 'red' }}>{errors.confirmpassword}</p>
                  }
                </div> */}
                <div class="form-group button_new_home banner">
                  <Button disabled={isLoad} onClick={() => { submitRegister() }}>Register</Button>
                </div>
                <p>You have an account yet?<Link to="/login" className="ml-2">Login</Link></p>
                {isLoad &&
                  <div class="form-group">
                    <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading-register" />
                  </div>
                }
              </form>
              {/* <p className="content_clas_sd">Invite to Our Metaverse and Win 20 Inorbitverse tokens</p> */}
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <SuccessModal />

    </div>
  );
}
