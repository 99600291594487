import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/home.js";
import ReactDatatable from '@ashvin27/react-datatable';
import DashSideNavbar from "components/DashSideNavbar.js";

import { dateFormat } from '../helper/dateFormat';
import { UserHistory } from "../Api/Myearning";

const dashboardRoutes = [];

var tableConfig = {
  page_size: 6,
  length_menu: [5, 10, 20, 50]
}


const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function MyEarning(props) {
  const classes = useStyles();
  const { ...rest } = props;

  var roiColumns = [
    {
      key: "createdAt",
      text: "Date & Time",
      className: "createdAt",
      align: "left",
      sortable: false,
      cell: record => {
        var date = dateFormat(record.createdAt, 'DD-MM-YYYY hh:mm A');
        return date;
      }
    },
    {
      key: "address",
      text: "Address",
      className: "address",
      sortable: false
    },
    {
      key: "amount",
      text: "Amount",
      className: "amount",
      sortable: false,
      cell: record => {
        var amount = parseFloat(record.amount);
        amount = (amount > 0) ? amount.toFixed(2) : 0;
        return amount;
      }
    },
    {
      key: "userpackage",
      text: "Levels",
      className: "amount",
      sortable: false
    }
  ];

  var levelColumns = [
    {
      key: "createdAt",
      text: "Date & Time",
      className: "createdAt",
      align: "left",
      sortable: false,
      cell: record => {
        var date = dateFormat(record.createdAt, 'DD-MM-YYYY hh:mm A');
        return date;
      }
    },
    {
      key: "fromid",
      text: "ID",
      className: "address",
      sortable: false
    },
    {
      key: "fromaddress",
      text: "Address",
      className: "address",
      sortable: false
    },
    {
      key: "amount",
      text: "Amount",
      className: "amount",
      sortable: false,
      cell: record => {
        var amount = parseFloat(record.amount);
        amount = (amount > 0) ? amount.toFixed(2) : 0;
        return amount;
      }
    },
    {
      key: "userpackage",
      text: "Levels",
      className: "amount",
      sortable: false
    }
  ];

  var inorbitColumns = [
    {
      key: "createdAt",
      text: "Date & Time",
      className: "createdAt",
      align: "left",
      sortable: false,
      cell: record => {
        var date = dateFormat(record.createdAt, 'DD-MM-YYYY hh:mm A');
        return date;
      }
    },
    {
      key: "fromid",
      text: "ID",
      className: "address",
      sortable: false
    },
    {
      key: "fromaddress",
      text: "Address",
      className: "address",
      sortable: false
    },
    {
      key: "tokenamount",
      text: "Amount",
      className: "amount",
      sortable: false,
      cell: record => {
        var amount = parseFloat(record.tokenamount);
        amount = (amount > 0) ? amount.toFixed(2) : 0;
        return amount;
      }
    },
    {
      key: "userpackage",
      text: "Levels",
      className: "amount",
      sortable: false
    }
  ];

  var referalColumns = [
    {
      key: "createdAt",
      text: "Date & Time",
      className: "createdAt",
      align: "left",
      sortable: false,
      cell: record => {
        var date = dateFormat(record.createdAt, 'DD-MM-YYYY hh:mm A');
        return date;
      }
    },
    {
      key: "fromid",
      text: "ID",
      className: "address",
      sortable: false
    },
    {
      key: "fromaddress",
      text: "Address",
      className: "address",
      sortable: false
    },
    {
      key: "amount",
      text: "Amount",
      className: "amount",
      sortable: false,
      cell: record => {
        var amount = parseFloat(record.amount);
        amount = (amount > 0) ? amount.toFixed(2) : 0;
        return amount;
      }
    },
    {
      key: "userpackage",
      text: "Levels",
      className: "amount",
      sortable: false
    }
  ];



  const [List, setList] = useState([]);

  useEffect(() => {

    getRoiList("daily");

  }, []);

  async function getRoiList(roitype) {
    var { result } = await UserHistory(roitype)
    setList(result)
  }

  async function changeList(roitype) {
    setList([]);
    var { result } = await UserHistory(roitype)
    setList(result)
  }

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksAfterlogin />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader dash_bg_odd"}>
        <div className="container">
          <GridContainer>
            <GridItem sm={12} md={12} lg={3}>
              <DashSideNavbar />
            </GridItem>
            <GridItem sm={12} md={12} lg={9}>
              <div className="dash_right_setion">
                <div className="dash_box">
                  <h2 className="inner_title">My Earning</h2>
                  <nav className="tab_scroll">
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <a class="nav-link active" id="nav-roi-tab" data-toggle="tab" href="#nav-roi" role="tab" aria-controls="nav-roi" aria-selected="true" onClick={() => changeList("daily")}>ROI</a>
                      <a class="nav-link" id="nav-level-income-tab" data-toggle="tab" href="#nav-level-income" role="tab" aria-controls="nav-level-income" aria-selected="false" onClick={() => changeList("levelincome")}>Level Income</a>
                      {/* <a class="nav-link" id="nav-inorbit-booster-tab" data-toggle="tab" href="#nav-inorbit-booster" role="tab" aria-controls="nav-inorbit-booster" aria-selected="false" onClick={() => changeList("inorbitbooster")}>Inorbit Booster</a> */}
                      {/* <a class="nav-link" id="nav-inorbit-benefits-tab" data-toggle="tab" href="#nav-inorbit-benefits" role="tab" aria-controls="nav-inorbit-benefits" aria-selected="false" onClick={() => changeList("inorbitbenefit")}>Inorbit Benefits</a> */}
                      <a class="nav-link" id="nav-referral-income-tab" data-toggle="tab" href="#nav-referral-income" role="tab" aria-controls="nav-referral-income" aria-selected="false" onClick={() => changeList("directreferal")}>Referral Income</a>
                    </div>
                  </nav>
                  <div class="tab-content mt-4" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-roi" role="tabpanel" aria-labelledby="nav-roi-tab">
                      <div className="primary_table_div">
                        <div className="table-responsive">
                          <ReactDatatable
                            config={tableConfig}
                            records={List}
                            columns={roiColumns}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="nav-level-income" role="tabpanel" aria-labelledby="nav-level-income-tab">
                      <div className="primary_table_div">
                        <div className="table-responsive">
                          <ReactDatatable
                            config={tableConfig}
                            records={List}
                            columns={levelColumns}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="nav-inorbit-booster" role="tabpanel" aria-labelledby="nav-inorbit-booster-tab">
                      <div className="primary_table_div">
                        <div className="table-responsive">
                          <ReactDatatable
                            config={tableConfig}
                            records={List}
                            columns={inorbitColumns}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="nav-inorbit-benefits" role="tabpanel" aria-labelledby="nav-inorbit-benefits-tab">
                      <div className="primary_table_div">
                        <div className="table-responsive">
                          <ReactDatatable
                            config={tableConfig}
                            records={List}
                            columns={inorbitColumns}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="nav-referral-income" role="tabpanel" aria-labelledby="nav-referral-income-tab">
                      <div className="primary_table_div">
                        <div className="table-responsive">
                          <ReactDatatable
                            config={tableConfig}
                            records={List}
                            columns={referalColumns}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}