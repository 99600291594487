
export async function toFixedFormat(amount) {

    var result = 0;

    try {
        var number = amount.toString();
        var number1 = number.split(".");

        if (number1[1] && number1[1].length && number1[1].length > 0) {
            var length = number1[1].length;
            if (length > 5) {
                length = 3;
            }
            result = amount.toFixed(length);
        } else {
            result = amount;
        }

        result = await isNumberCheck(result);

        return result;

    } catch (err) {
        return result;
    }

}


export async function numberFloatOnly(value) {
    const regxFormat = /^[+-]?\d*(?:[.,]\d*)?$/;
    var result = regxFormat.test(value)
    return result;
}

export async function numberOnly(value) {
    const regxFormat = /^[0-9-+()]*$/;
    var result = regxFormat.test(value)
    return result;
}

export async function formatAddress(address) {
    try {
        var addr = address.substring(0, 16);
        var addr1 = address.substring(30, 42);
        var concat = addr + "...." + addr1;
        return concat;
    } catch (err) {
        return "";
    }
}



export async function isNumberCheck(amount) {
    var numberVal = amount;
    var convertamt = (isFinite(numberVal) && numberVal > 0 && numberVal !== "Infinity") ? numberVal : 0;
    return convertamt;
}

export async function ChecktokenDecimal(amount, decimals) {

    var result = 0;
    var decimalsLength = 18;
    if (decimals && decimals > 0) {
        decimalsLength = decimals;
    }

    try {
        var number = amount.toString();
        var number1 = number.split(".");

        if (number1[1] && number1[1].length && number1[1].length > 0) {
            var length = number1[1].length;
            if (length > decimalsLength) {
                length = decimals;
            }
            result = amount.toFixed(length);
        } else {
            result = amount;
        }

        result = await isNumberCheck(result);

        return result;

    } catch (err) {
        return result;
    }

}