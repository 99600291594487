import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/home.js";

import { toastAlert } from "../helper/toastAlert"
import { dateFormat } from "../helper/dateFormat"
import DashSideNavbar from "components/DashSideNavbar.js";
import config from "../config/config"
import { connection } from "../helper/connection";
import { saveStaking } from "../Api/StakingActions";
import ReactLoading from "react-loading";
import { Validate } from "../Validation/staking/validation"
import isEmpty from 'is-empty';
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

var adddays = new Date();
adddays.setDate(adddays.getDate() + 150);

var adddays1 = new Date();
adddays1.setDate(adddays1.getDate() + 300);

export default function Staking(props) {

  const classes = useStyles();
  const { ...rest } = props;

  const [currentPlan, setcurrentPlan] = React.useState({
    plan: 1, days: 150,
    amount: "", agree: "",
    start: "", end: ""
  });
  const [errors, seterrors] = React.useState({});
  const [isLoad, setisLoad] = React.useState(false);
  const [userbal, setuserbal] = React.useState({ balance: 0, balanceOf: 0 });

  useEffect(() => {

    async function geBalance() {

      var get = await connection();

      if (get && get.tronWeb && get.tronWeb !== "") {
        var tronWebConn = get.tronWeb;
        var address = get.address;
        var contract = await tronWebConn.contract().at(config.USDT);
        var balanceOf = await contract.balanceOf(address).call();
        balanceOf = await tronWebConn.toDecimal(balanceOf._hex)
        var decimals = await contract.decimals().call();
        var balance = balanceOf / 10 ** decimals;
        setuserbal({
          balance: balance,
          balanceOf: balanceOf,
        })
      }

    }

    setTimeout(function () {
      geBalance();
    }, 1200)


  }, []);

  async function selectPlan(plan, days) {
    seterrors({});
    var formData = { ...currentPlan, ...{ "plan": plan, "days": days, "amount": "" } }
    setcurrentPlan(formData);
  }

  async function submitStaking() {

    try {

      var get = await connection();
      var isError = await Validate(currentPlan);
      seterrors({});
      if (!isEmpty(isError)) {
        seterrors(isError)
        return;
      }
      setisLoad(true);
      if (get && get.tronWeb && get.tronWeb !== "") {

        var tronWebConn = get.tronWeb;
        var address = get.address;

        // if (get.fullHost !== config.fullHost) {
        //   setisLoad(false);
        //   toastAlert('error', "Please login  into tronlink mainet", 'tronlink');
        //   return;
        // }
        try {
          var checkLive = await tronWebConn.contract().at(config.InorbitContract);
        } catch (err) {
          setisLoad(false);
          toastAlert('error', "Please login  into tronlink mainet", 'tronlink');
          return;
        }

        var contract = await tronWebConn.contract().at(config.USDT);
        var balanceOf = await contract.balanceOf(address).call();
        var decimals = await contract.decimals().call();
        var allowance = await contract.allowance(address, config.InorbitContract).call();
        allowance = await tronWebConn.toDecimal(allowance._hex);;

        var balance = balanceOf / parseFloat(10 ** decimals);
        var buyAmt = parseFloat(currentPlan.amount) * parseFloat(10 ** decimals);

        if (currentPlan.amount <= balance) {
          try {

            if (allowance < buyAmt) {
              var approveAmt = 1000000 * 10 ** decimals;
              await contract.approve(config.InorbitContract, approveAmt).send({
                feeLimit: 1000000000,
                callValue: approveAmt,
                shouldPollResponse: false
              });
            }

            var inorbitContract = await tronWebConn.contract().at(config.InorbitContract);
            var result = await inorbitContract.stakePlan(currentPlan.plan, buyAmt).send({
              feeLimit: 1000000000,
              callValue: buyAmt,
              shouldPollResponse: false
            });

            var data = {
              planDetails: currentPlan,
              address: address,
              txid: result
            }
            toastAlert('success', "Please wait you transaction is processing", 'purchased');
            var resp = await saveStaking(data);
            setisLoad(false);

            if (resp && resp.message === "valid") {
              var formData = { ...currentPlan, ...{ "amount": "" } }
              setcurrentPlan(formData);
              toastAlert('success', "Successfully purchased", 'successpurchased');
            } else if (resp && resp.message === "invalid") {
              toastAlert('success', "Failed your transaction", 'successpurchased');
            } else {
              toastAlert('error', "Oops something went wrong.!", 'successpurchased');
            }
          } catch (err) {
            setisLoad(false);
          }
        } else {
          setisLoad(false);
          toastAlert('error', "Insufficient balance", 'successpurchased');
        }

      } else {
        toastAlert('error', "Please login inot tronlink!", 'successpurchased');
      }
    } catch (err) {
      setisLoad(false);
    }

  }

  const inputChange = async (event) => {
    var value = event.target.value;
    var formData = { ...currentPlan, ...{ "amount": value } }
    setcurrentPlan(formData);
  }


  const approveAgree = async (event) => {
    let isChecked = event.target.checked;
    var formData = { ...currentPlan, ...{ "agree": isChecked } }
    setcurrentPlan(formData);
  }

  async function setMax() {
    var formData = { ...currentPlan, ...{ "amount": userbal.balance } }
    setcurrentPlan(formData);
  }

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksAfterlogin />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader dash_bg_odd"}>
        <div className="container">
          <GridContainer>
            <GridItem sm={12} md={12} lg={3}>
              <DashSideNavbar />
            </GridItem>
            <GridItem sm={12} md={12} lg={9}>
              <div className="dash_right_setion">
                <div className="dash_box">
                  <h2 className="inner_title">Staking</h2>
                  <nav className="staking_tab">
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <a onClick={() => { selectPlan(1, 150) }} class="nav-link active" id="nav-staking-150-tab" data-toggle="tab" href="#nav-staking-150" role="tab" aria-controls="nav-staking-150" aria-selected="true">
                        <p>Duration<span>150</span>Days</p>
                      </a>
                      <a onClick={() => { selectPlan(2, 300) }} class="nav-link" id="nav-staking-300-tab" data-toggle="tab" href="#nav-staking-300" role="tab" aria-controls="nav-staking-300" aria-selected="false">
                        <p>Duration<span>300</span>Days</p>
                      </a>
                    </div>
                  </nav>
                  <div class="tab-content mt-4" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-staking-150" role="tabpanel" aria-labelledby="nav-staking-150-tab">
                      <div className="staking_panel">
                        <h2>Duaration 150 Days</h2>
                        <div className="staking_panel_details">
                          <div>
                            <label>Total Amount</label>
                            <label>{(currentPlan.amount) ? currentPlan.amount + " USDT" : "-"}</label>
                          </div>
                          <div>
                            <label>Stake Date</label>
                            <label>{dateFormat(new Date(), "DD-MM-YYYY hh:mm")}</label>
                          </div>
                          <div>
                            <label>Locked Period</label>
                            <label>150 Days</label>
                          </div>
                          <div>
                            <label>Interest End Date</label>
                            <label>{dateFormat(adddays, "DD-MM-YYYY hh:mm")}</label>
                          </div>
                          <div>
                            <label>Accrue Days</label>
                            <label>150 Days</label>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <label className="text-white">Amount</label>
                          <label className="text-white">Balance: <span className="yellow_txt">{(userbal.balance > 0) ? userbal.balance.toFixed(2) : 0} USDT</span></label>
                        </div>
                        <div className="inp_grp mt-3 mb-3">
                          <input type="text" value={currentPlan.amount} onChange={inputChange} placeholder="Enter Amount" className="primary_input" />
                          <Button onClick={() => { setMax() }} className="btn max_btn" type="button">Max</Button>
                          <span className="currency_type">USDT</span>
                        </div>
                        <div class="custom-control custom-checkbox">
                          <input onClick={approveAgree} type="checkbox" class="custom-control-input" id="customCheck1" />
                          <label class="custom-control-label" for="customCheck1">I have read and I agree to Conceptenx Global Staking Serivce Agreement</label>
                        </div>
                        <div className="text-center mt-4 mb-3">
                          {errors && errors.message && errors.message !== "" ?
                            <p style={{ color: 'red' }}>{errors.message}</p>
                            :
                            <p style={{ color: 'red' }}>{errors.agree}</p>

                          }
                          <Button className="primary_btn primary_btn_extralarge" onClick={() => { submitStaking() }}>Confirm Purchase</Button>
                        </div>
                        {isLoad &&
                          <div class="form-group button_new_home banner">
                            <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading stakingloader" />
                          </div>
                        }
                      </div>
                    </div>
                    <div class="tab-pane fade" id="nav-staking-300" role="tabpanel" aria-labelledby="nav-staking-300-tab">
                      <div className="staking_panel">
                        <h2>Duaration 300 Days</h2>
                        <div className="staking_panel_details">
                          <div>
                            <label>Total Amount</label>
                            <label>{(currentPlan.amount) ? currentPlan.amount + " USDT" : "-"}</label>
                          </div>
                          <div>
                            <label>Stake Date</label>
                            <label>{dateFormat(new Date(), "DD-MM-YYYY hh:mm")}</label>
                          </div>
                          <div>
                            <label>Locked Period</label>
                            <label>300 Days</label>
                          </div>
                          <div>
                            <label>Interest End Date</label>
                            <label>{dateFormat(adddays1, "DD-MM-YYYY hh:mm")}</label>
                          </div>
                          <div>
                            <label>Accrue Days</label>
                            <label>300 Days</label>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <label className="text-white">Lock Amount</label>
                          <label className="text-white">Balance: <span className="yellow_txt">{(userbal.balance > 0) ? userbal.balance.toFixed(2) : 0} USDT</span></label>
                        </div>
                        <div className="inp_grp mt-3 mb-3">
                          <input type="text" value={currentPlan.amount} onChange={inputChange} placeholder="Enter Amount" className="primary_input" />
                          <Button onClick={() => { setMax() }} className="btn max_btn" type="button">Max</Button>
                          <span className="currency_type">USDT</span>
                        </div>

                        <div class="custom-control custom-checkbox">
                          <input onClick={approveAgree} type="checkbox" class="custom-control-input" id="customCheck2" />
                          <label class="custom-control-label" for="customCheck2">I have read and I agree to Conceptenx Global Staking Serivce Agreement</label>
                        </div>
                        <div className="text-center mt-4 mb-3">
                          {errors && errors.message && errors.message !== "" ?
                            <p style={{ color: 'red' }}>{errors.message}</p>
                            :
                            <p style={{ color: 'red' }}>{errors.agree}</p>

                          }
                          <Button type="button" onClick={() => { submitStaking() }} className="primary_btn primary_btn_extralarge">Confirm Purchase</Button>
                        </div>

                        {isLoad &&
                          <div class="form-group button_new_home banner">
                            <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading stakingloader" />
                          </div>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}