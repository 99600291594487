import React, { useEffect, useState, Fragment } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/home.js";
import isEmpty from 'is-empty';
import ReactLoading from "react-loading";
import DashSideNavbar from "components/DashSideNavbar.js";
import { Validate, ValidateReply } from "../Validation/supportticket/validation"
import {
  saveTicket,
  ticketHistory,
  getTicket,
  saveReply,
  SubmitClose
} from '../Api/TicketActions';
import { toastAlert } from '../helper/toastAlert';
import { dateFormat } from '../helper/dateFormat';
import config from '../config/config';
import ReactDatatable from '@ashvin27/react-datatable';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

var initialData = {
  "issuestype": "",
  "email": "",
  "description": ""
}

var ticketData = {
  "id": "",
  "issues": "",
  "description": "",
  "date": "",
  "ticketId": "",
  "status": "",
  "reply": []
}

var tableConfig = {
  page_size: 6,
  length_menu: [5, 10, 20, 50]
}

export default function SupportTicket(props) {

  var tableColumns = [
    {
      key: "ticketid",
      text: "Ticket ID",
      className: "name",
      align: "left",
      sortable: false,
    },
    {
      key: "createdAt",
      text: "Created On",
      className: "createdAt",
      align: "left",
      sortable: true,
      cell: record => {
        var date = dateFormat(record.createdAt, 'DD-MM-YYYY hh:mm A');
        return date;
      }
    },
    {
      key: "issues",
      text: "Issue",
      className: "issues",
      sortable: false
    },
    {
      key: "status",
      text: "Status",
      className: "Status",
      align: "left",
      sortable: false,
      cell: record => {
        var txt = "Closed"
        var className1 = "green_txt"
        if (record.status === "open") {
          txt = "Open"
          className1 = "red_txt"
        }
        return (
          <Fragment>
            <p
              className={className1}
              onClick={() => editRecord(record)}
              style={{ marginRight: '5px' }}>
              {txt}
            </p>

          </Fragment>
        );
      }
    }

  ];

  const classes = useStyles();
  const { ...rest } = props;

  const userData = useSelector((state) => state.authReducer);

  const [formValue, setformValue] = useState(initialData);
  const [errors, seterrors] = useState({});
  const [isLoad, setisLoad] = useState(false);
  const [ticketList, setticketList] = useState([]);
  const [showList, setshowList] = useState(false);
  const [recentTicket, setrecentTicket] = useState(ticketData);
  const [replymsg, setreplymsg] = useState("");
  const [replyerrors, setreplyerrors] = useState({});
  const [isReply, setisReply] = useState(false);


  async function editRecord() {
  }

  useEffect(() => {

    async function getTicketList() {
      setshowList(false)
      var { result } = await ticketHistory();

      if (result && result.length > 0) {
        var lastPos = result[result.length - 1];
        getLastTicket(lastPos);
      }

      setticketList(result);
      setshowList(true);
    }

    getTicketList();

  }, []);



  async function getLastTicket(item) {


    var { result } = await getTicket({ _id: item._id });
    if (result && result._id) {
      setrecentTicket({
        "id": result._id,
        "status": result.status,
        "issues": result.issues,
        "description": result.description,
        "date": result.createdAt,
        "ticketid": result.ticketid,
        "reply": result.reply,
      })
    }
  }

  const inputChange = async (event) => {

    var id = event.target.id;
    var value = event.target.value;

    var formData = { ...formValue, ...{ [id]: value } }
    var isError = await Validate(formData);

    seterrors({});
    if (!isEmpty(isError)) {
      seterrors(isError)
    }

    setformValue(formData);
  }

  async function submitTicket() {

    var isError = await Validate(formValue);

    seterrors({});
    if (!isEmpty(isError)) {
      seterrors(isError)
      return;
    } else {
      setisLoad(true);
      var { message, errors } = await saveTicket(formValue);
      if (!isEmpty(errors)) {
        seterrors(errors)
      } else if (message === "added") {
        var Alertmessage = "Hi " + userData.name + ", I'm reaching out about your case with us regarding " + formValue.issuestype + ". It's been 1 or 2 days since we've heard from you, so I wanted to reach out and let you know we are going to close this ticket"
        toastAlert('success', Alertmessage, 'login');
        setformValue(initialData);
      }
      setisLoad(false);
    }

  }

  async function submitReply(id) {

    var isError = await ValidateReply(replymsg);
    setreplyerrors({});
    if (!isEmpty(isError)) {
      setreplyerrors(isError);
      return;
    }
    setisReply(true);
    var { result } = await saveReply({ id: id, message: replymsg });
    if (result.description && result.description !== "") {
      recentTicket.reply.push(result);
      var formData = { ...recentTicket, ...recentTicket }
      setrecentTicket(formData);
      setreplymsg("")
    }
    setisReply(false);


  }

  const replyChange = async (event) => {

    var value = event.target.value;
    var isError = await ValidateReply(value);
    setreplyerrors({});
    if (!isEmpty(isError)) {
      setreplyerrors(isError)
    }

    setreplymsg(value);
  }

  async function closeTicket(id) {
    await SubmitClose({ id: id })
    var formData = { ...recentTicket, ...{ status: "closed" } }
    setrecentTicket(formData);

    try {
      var index = ticketList.findIndex(val => val._id.toString() === id.toString());
      if (index !== -1) {
        setshowList(false)
        ticketList[index].status = "closed";
        setticketList(ticketList)
        setshowList(true)
      }
    } catch (err) {

    }


  }

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksAfterlogin />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader dash_bg_odd"}>
        <div className="container">
          <GridContainer>
            <GridItem sm={12} md={12} lg={3}>
              <DashSideNavbar />
            </GridItem>
            <GridItem sm={12} md={12} lg={9}>
              <div className="dash_right_setion">
                <div className="dash_box">
                  <h2 className="inner_title">Support Ticket</h2>
                  <form className="profile_form">
                    <h4>Create Support Ticket</h4>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="name">Select Your Issue</label>
                        <select value={formValue.issuestype} className="primary_input custom-select" id="issuestype" onChange={inputChange}>
                          <option value="">Select Your Issue Type</option>
                          <option value="Withdraw Issue">Withdraw Issue</option>
                          <option value="Deposit Issue">Deposit Issue</option>
                          <option value="Transfer Issue">Transfer Issue</option>
                        </select>
                        {errors && errors.issuestype && errors.issuestype !== "" &&
                          <p style={{ color: 'red' }}>{errors.issuestype}</p>
                        }
                      </div>
                      <div class="form-group col-md-6">
                        <label for="email">Your Alternative Email</label>
                        <input
                          type="email"
                          className="primary_input"
                          id="email"
                          value={formValue.email}
                          onChange={inputChange}
                        />
                        {errors && errors.email && errors.email !== "" &&
                          <p style={{ color: 'red' }}>{errors.email}</p>
                        }
                      </div>
                      <div class="form-group col-md-12">
                        <label for="desc">Describe Your Issue</label>
                        <textarea
                          rows={4}
                          className="primary_input"
                          id="description"
                          value={formValue.description}
                          onChange={inputChange}
                        >{formValue.description}</textarea>
                        {errors && errors.description && errors.description !== "" &&
                          <p style={{ color: 'red' }}>{errors.description}</p>
                        }
                      </div>
                    </div>
                    <Button disabled={isLoad} type="button" onClick={() => { submitTicket() }} className="primary_btn">Submit</Button>
                    {isLoad &&
                      <div class="form-group button_new_home banner">
                        <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading" />
                      </div>
                    }
                  </form>

                  <div className="primary_table_div mt-5">
                    <div className="support_ticket_head">
                      <h3>Ticket History</h3>
                      <div className="inp_grp">
                        <input className="search_inp" placeholder="Search  Ticket ID/ Date" />
                        <img src={require("../assets/images/search_icon.png")} alt="Search" className="search_icon" />
                      </div>
                    </div>
                    <div className="table-responsive">
                      {showList &&
                        <ReactDatatable
                          config={tableConfig}
                          records={ticketList}
                          columns={tableColumns}
                        />
                      }
                    </div>
                  </div>
                  {recentTicket.ticketid && recentTicket.ticketid != "" &&
                    <div className="ticket_description_panel">
                      <h3>Description</h3>
                    </div>
                  }
                  <div className="support_ticket_desc_panel">
                    {recentTicket.ticketid && recentTicket.ticketid != "" &&
                      <div className="support_ticket_desc_row">
                        <div>
                          <img src={require("../assets/images/support_ticket_user.png")} className="img-fluid user_img" alt="Icon" />
                          <h4>{userData.name}</h4>
                        </div>
                        <div className="ticket_details">
                          <h5>{recentTicket.issues}</h5>
                          <div className="ticket_time">
                            <h6><span>Created on</span> {dateFormat(recentTicket.date, 'DD-MM-YYYY hh:mm A')}  |  <span>Ticket ID:</span> {recentTicket.ticketid}</h6>
                            <h6><span>Status:</span> {(recentTicket.status === "open") ? "Open" : "Closed"}</h6>
                          </div>
                          <p className="yellow_txt"><b>Hello Admin,</b></p>
                          <p>{recentTicket.description}</p>
                          <p>Thanks, <br /> {userData.name}</p>
                        </div>
                      </div>
                    }

                    {recentTicket && recentTicket.reply.length > 0 && recentTicket.reply.map((item, i) => {
                      return (

                        item.replyby === "admin" ?
                          <div className="support_ticket_desc_row">
                            <div>
                              <img src={require("../assets/images/support_ticket_admin.png")} className="img-fluid user_img" alt="Icon" />
                              <h4>Admin</h4>
                            </div>


                            <div className="ticket_details">
                              <div className="ticket_time">
                                <h6><span>Admin reply on {dateFormat(item.createdAt, 'DD-MM-YYYY hh:mm A')}</span></h6>
                              </div>
                              <p>Hello {userData.name},</p>
                              <p>{item.description}</p>
                              <p>Thanks, <br /> Admin</p>
                            </div>
                          </div>
                          :
                          <div className="support_ticket_desc_row">
                            <div>
                              <img src={require("../assets/images/support_ticket_user.png")} className="img-fluid user_img" alt="Icon" />
                              <h4>Admin</h4>
                            </div>


                            <div className="ticket_details">
                              <div className="ticket_time">
                                <h6><span>Created on {dateFormat(item.createdAt, 'DD-MM-YYYY hh:mm A')}</span></h6>
                              </div>
                              <p>Hello Admin,</p>
                              <p>{item.description}</p>
                              <p>Thanks, <br /> {userData.name}</p>
                            </div>
                          </div>

                      )
                    })}

                    {recentTicket.status === "open" &&
                      <form className="support_reply_form">
                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <textarea
                              rows={4}
                              className="primary_input"
                              id="replymsg"
                              onChange={replyChange}
                              value={replymsg}
                            >{replymsg}</textarea>
                            {replyerrors && replyerrors.replymsg && replyerrors.replymsg !== "" &&
                              <p style={{ color: 'red' }}>{replyerrors.replymsg}</p>
                            }
                          </div>
                        </div>
                        <div className="text-left mt-3">
                          <Button
                            type="button"
                            disabled={isReply}
                            onClick={() => { submitReply(recentTicket.id) }}
                            className="primary_btn">Reply</Button>

                          <Button
                            onClick={() => { closeTicket(recentTicket.id) }}
                            className="tick_close_btn mt-3 mt-sm-0 mt-md-0 mt-lg-0 ml-0 ml-sm-3 ml-md-3 ml-lg-3">
                            Statisfied, Close this ticket
                        </Button>
                        </div>
                      </form>
                    }
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
