/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses + " footer_home"}>
      <div className={classes.container}>
        <div className="footer_bottom_home">
          <div className="text-center">
            <div className="footer_social_links">
              <ul>
                {/* <li><a href="https://t.me/InorbitGlobalChannel" target="_blank"><i className="fab fa-telegram"></i></a></li>
                <li><a href="https://www.instagram.com/inorbitglobal0/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                <li><a href="https://twitter.com/InorbitGlobal" target="_blank"><i className="fab fa-twitter"></i></a></li>
                <li><a href="https://www.youtube.com/channel/UCYwjbaOTyCPbv-BBV1HWXnw/about" target="_blank"><i className="fab fa-youtube"></i></a></li>
                <li><a href="https://www.facebook.com/inorbitglobal1/" target="_blank"><i className="fab fa-facebook"></i></a></li> */}
                {/* <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li> */}

                {/* <li><a href="#" target="_blank"><i className="fab fa-youtube"></i></a></li> */}
              </ul>
            </div>
            {/* <p>Smart-contract</p>
            <Link to="/" className="ft_contract">TT4zhogLvjcAPe9RJgrNngTFZjpJHJsW2d</Link> */}
            <Link className="ft_contract">info@conceptenx.ai</Link>
          </div>

        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
