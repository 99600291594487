import { numberFloatOnly } from '../../helper/custommath'

export async function Validate(data) {

    var errors = {};
    var planAmt = data.amount;
    var agree = data.agree;
    var status = await numberFloatOnly(planAmt);

    if (planAmt === "" || planAmt <= 0 || !status) {
        errors.message = "Please enter valid amount";
    }
    if (!agree) {
        errors.agree = "Please agree to Inorbit Global Staking Serivce Agreement";
    }

    return errors;
}