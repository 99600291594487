import axios from "axios";
import config from "../config/config";

const baseUrl = config.baseUrl;

export const upgradeLevel = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/upgrade-level`,
            'data': data,
            'headers': {
                'authtoken': localStorage.getItem('accessToken')
            }
        });
        return {
            message: respData.data.message,
            errors: respData.data.errors,

        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        return err.response.data.errors;
    }
    else {
        return '';
    }
}


