let key = {};
if (process.env.NODE_ENV === "production") {
  key = {
    baseUrl: "https://api.conceptenx.com/api",
    frontUrl: "https://www.conceptenx.com/",
    loaderColor: "#f6b844",
    fullHost: "https://api.trongrid.io",
    USDT: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
    decimals: 1000000,
    mainnet: true,
    networkUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    trxUrl: "https://tronscan.org/#/transaction/",
    InorbitContract: "TX2NbNmYk8gNf77nuBm75NnoNWpHeuMRpg"
  };
} else {
  key = {
    baseUrl: "http://localhost:2053/api",
    frontUrl: "https://www.conceptenx.com/",
    loaderColor: "#f6b844",
    fullHost: "https://api.trongrid.io",
    USDT: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
    decimals: 1000000,
    mainnet: true,
    networkUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    trxUrl: "https://tronscan.org/#/transaction/",
    InorbitContract: "TX2NbNmYk8gNf77nuBm75NnoNWpHeuMRpg"
  };
}

export default key;
