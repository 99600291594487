import TronWeb from 'tronweb';
import config from '../../config/config';

import Web3 from "web3";

var tronWeb = new TronWeb({
    fullHost: config.fullHost
});

export function isAddress(address) {
    var isValid = tronWeb.isAddress(address);
    return isValid;
}

export function isBEP20Address(address) {
    var web3 = new Web3(config.networkUrl);
    var isValid = web3.utils.isAddress(address);
    return isValid;
}

