import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import isEmpty from 'is-empty';
import { Button } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/home.js";
import { useSelector } from 'react-redux';
import DashSideNavbar from "components/DashSideNavbar.js";
import { toastAlert } from '../helper/toastAlert';
import { getProfile, updateProfile } from "../Api/UserActions"
import { profileValidate } from "../Validation/profile/validation"

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

var initialData = {
  address: "",
  email: "",
  name: "",
  id: "",
  bep20address: ""
}

export default function Profile(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const userData = useSelector((state) => state.authReducer);

  const [userInfo, setuserInfo] = useState(initialData);
  const [errors, seterrors] = useState({});

  useEffect(() => {
    getProfileData();
  }, [userData]);

  async function getProfileData() {
    if (userData && userData.id && userData.id !== "") {
      var { result } = await getProfile({ id: userData.id });
      setuserInfo(result)
    }
  }

  const inputChange = async (event) => {

    var id = event.target.id;
    var value = event.target.value;

    var formData = { ...userInfo, ...{ [id]: value } }
    var isError = await profileValidate(formData);

    seterrors({});
    if (!isEmpty(isError)) {
      seterrors(isError)
    }

    setuserInfo(formData);
  }

  async function submitProfile() {

    var newObj = userInfo;
    Object.assign(newObj, { id: userData.id });
    var isError = await profileValidate(userInfo);
    seterrors({});
    if (!isEmpty(isError)) {
      seterrors(isError);
      return;
    } else {
      await updateProfile(newObj);
      toastAlert('success', "Profile updated successfully", 'profile');
    }

  }


  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksAfterlogin />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader dash_bg"}>
        <div className="container">
          <GridContainer>
            <GridItem sm={12} md={12} lg={3}>
              <DashSideNavbar />
            </GridItem>
            <GridItem sm={12} md={12} lg={9}>
              <div className="dash_right_setion">
                <div className="dash_box">
                  <h2 className="inner_title">My Profile</h2>
                  <form className="profile_form">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="name">Meta Crazy Name</label>
                        <input
                          type="text"
                          value={userInfo.name}
                          className="primary_input"
                          id="name"
                          onChange={inputChange}
                        />
                        {errors && errors.name && errors.name !== "" &&
                          <p style={{ color: 'red' }}>{errors.name}</p>
                        }
                      </div>
                      <div class="form-group col-md-6">
                        <label for="address">TRC-20 Dapp Address</label>
                        <input
                          type="text"
                          value={userInfo.address}
                          className="primary_input"
                          id="address"
                          readOnly
                          onChange={inputChange}
                        />
                        {errors && errors.address && errors.address !== "" &&
                          <p style={{ color: 'red' }}>{errors.address}</p>
                        }
                      </div>
                      {/* <div class="form-group col-md-6">
                        <label for="address">BEP-20 Dapp Address</label>
                        <input
                          type="text"
                          value={userInfo.bep20address}
                          className="primary_input"
                          id="bep20address"
                          onChange={inputChange}
                        />
                        {errors && errors.bep20address && errors.bep20address !== "" &&
                          <p style={{ color: 'red' }}>{errors.bep20address}</p>
                        }
                      </div> */}
                      <div class="form-group col-md-6">
                        <label for="email">Email ID</label>
                        <input type="email" onChange={inputChange} value={userInfo.email} className="primary_input" id="email" />
                      </div>

                    </div>
                    <Button type="button" onClick={() => { submitProfile() }} className="primary_btn mt-3">Save</Button>
                    {/* <h3>Change Password</h3>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="password">Old Password</label>
                        <input
                          type="password"
                          className="primary_input"
                          id="oldpassword"
                          value={pwdData.oldpassword}
                          onChange={inputChangePwd}
                        />
                        {pwderrors && pwderrors.oldpassword && pwderrors.oldpassword !== "" &&
                          <p style={{ color: 'red' }}>{pwderrors.oldpassword}</p>
                        }
                      </div>
                      <div class="form-group col-md-6">
                        <label for="cpassword">New Password</label>
                        <input
                          type="password"
                          className="primary_input"
                          id="newpassword"
                          value={pwdData.newpassword}
                          onChange={inputChangePwd}
                        />
                        {pwderrors && pwderrors.newpassword && pwderrors.newpassword !== "" &&
                          <p style={{ color: 'red' }}>{pwderrors.newpassword}</p>
                        }
                      </div>

                      <div class="form-group col-md-6">
                        <label for="cpassword">Confirm Password</label>
                        <input
                          type="password"
                          className="primary_input"
                          id="cpassword"
                          value={pwdData.cpassword}
                          onChange={inputChangePwd}
                        />
                        {pwderrors && pwderrors.cpassword && pwderrors.cpassword !== "" &&
                          <p style={{ color: 'red' }}>{pwderrors.cpassword}</p>
                        }
                      </div>
                    </div>
                    <Button type="button" onClick={() => { changepwd() }} className="primary_btn mt-3">Change</Button> */}
                  </form>


                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
