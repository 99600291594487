/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";


import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <div className="home_page_menu">
      <List className={classes.list + " main_navbar"}>

        <ListItem className={classes.listItem}>
          <Link to="/about-us" color="transparent" className="nav-link">About Us</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="#" color="transparent" className="nav-link">Our Plan </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/#" color="transparent" className="nav-link">Our Videos</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/#" color="transparent" className="nav-link">Our Acedemy</Link>
        </ListItem>
        {/* <ListItem className={classes.listItem}>
          <Button className="menu_btn"><Link to="/register" color="transparent" className="nav-link">Join Now</Link></Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button className="menu_btn"><Link to="/login" color="transparent" className="nav-link">Login</Link></Button>
        </ListItem> */}
      </List>
    </div>
  );
}
