import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import FooterHome from "components/Footer/FooterHome.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/home.js";
import { Link, useHistory } from "react-router-dom";

import { Button } from "@material-ui/core";
import Countdown from 'react-countdown';
import { activateUser } from '../Api/UserActions';
import { toastAlert } from '../helper/toastAlert';

// const Icon = styled(props => (
//   <div {...props}>
//     <div className="minus">-</div>
//     <div className="plus">+</div>
//   </div>
// ))`
//   & > .plus {
//     display: block;
//     color: #24272c;
//     font-size: 42px;
//     background-color: #383b40;
//     width: 50px;
//     height: 50px;
//     border-radius: 50%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   & > .minus {
//     display: none;
//     color: #24272c;
//     font-size: 42px;
//     background-color: #383b40;
//     width: 50px;
//     height: 50px;
//     border-radius: 50%;
//     justify-content: center;
//     align-items: center;
//   }
//   .Mui-expanded & > .minus {
//     display: flex;
//   }
//   .Mui-expanded & > .plus {
//     display: none;
//   }
// `;

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();

  const history = useHistory();

  useEffect(() => {
    var userId = (props.match && props.match.params && props.match.params.activateid) ? props.match.params.activateid : "";
    if (typeof userId !== "undefined" && userId !== "") {
      activateMail(userId);
    }
    if (localStorage.getItem('ispopupnew') !== "yes" || !localStorage.getItem('ispopupnew')) {
      //window.$('#myModal').modal('show');
      localStorage.setItem('ispopupnew', 'yes')
    }
  });

  async function activateMail(activateid) {

    let passdata = { _id: activateid };
    var response = await activateUser(passdata);
    if (response.message === "activated") {
      toastAlert('success', "Successfully activated your account", 'activate');
      // history.push('/login');
    } else if (response.message === "already") {
      toastAlert('error', "Already activated your account", 'activate');
    } else if (response.message === "invalid") {
      toastAlert('error', "Invalid link", 'activate');
    } else {
      toastAlert('error', "Oops something went wrong.!", 'activate');
    }

  }



  // Random component
  const Completionist = () => <span>You are good to go!</span>;

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <>
        <div className="timer_countown">
          <div>
            <span>{days}<small>days</small></span>
          </div>
          <div>
            <span>{hours}<small>hours</small></span>
          </div>
          <div>
            <span>{minutes}<small>minutes</small></span>
          </div>
          <div>
            <span>{seconds}<small>seconds</small></span>
          </div>
        </div>

      </>;
    }
  };





  const { ...rest } = props;

  return (
    <div>
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />


      <div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>











      <div className="page-header header-filter">
        <img src={require("../assets/images/rocket.png")} alt="logo" className="img-fluid log_section_rocket" />
        <div className={classes.container}>
          <GridContainer className="mt-5 align-items-center">
            <GridItem sm={12} md={10} lg={10} className="m-auto text-center" data-aos="fade-up" data-aos-duration="2000">
              {/* <div className="first_title">
                <div id="snow" class="hideMe"></div>
                <img src={require("../assets/images/title.gif")} alt="logo" className="img-fluid title_section_im" />
              </div> */}

              <h1 className="">World's 1<sup>st</sup> Crowdfunding with <br />
                Decentralized Space.</h1>
              <p>With All our Intelligence We create Artificial Intelligence</p>
              {/* {localStorage.getItem('accessToken') && localStorage.getItem('accessToken') != "" ?
                <div className="button_new_home banner">
                  <Button className="login"><Link to='/dashboard'>Login</Link></Button>
                  <Button className="login"><Link to='/dashboard'>Join Now</Link></Button>
                </div>
                :
                <div className="button_new_home banner">
                  <Button className="login"><Link to='login'>Login</Link></Button>
                  <Button className="login"><Link to='/register'>Join Now</Link></Button>
                </div>
              } */}
              <div className="button_new_home banner">
                <Button className="login loginnew"><Link to='login'>Login</Link></Button>
                <Button className="login"><Link to='/register'>Join Now</Link></Button>
              </div>

            </GridItem>
          </GridContainer>
        </div>
      </div>

      <div className="main">
        <div className="global_dis_section">
          <div className="container">
            <div className="section">
              <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <GridItem sm={12} md={6} lg={4}>
                  <div className="card_meta_">
                    <img src={require("../assets/images/vr_img.png")} alt="logo" className="img-fluid vr_img " />
                    <div>
                      <h3>METAVERSE</h3>
                      <p>Augmented reality Games with NEVER SEEN BEFORE</p>
                      <div className="button_new_home banner">

                      </div>
                    </div>

                  </div>
                </GridItem>
                <GridItem sm={12} md={6} lg={4}>
                  <div className="card_meta_">
                    <img src={require("../assets/images/momky.png")} alt="logo" className="img-fluid img_sectoinmomk" />
                    <div>
                      <h3>Non-Fungible <br />Tokens  </h3>
                      <p>A Unique on-chain digital assets</p>
                      <div className="button_new_home banner">

                      </div>
                    </div>

                  </div>
                </GridItem>
                <GridItem sm={12} md={6} lg={4}>
                  <div className="card_meta_">
                    <img src={require("../assets/images/robot.png")} alt="logo" className="img-fluid robot_sec" />
                    <div>
                      <h3>Advanced AI Algo Auto Trading MegaBot</h3>
                      <p>Allows two independent Parties to make crypto</p>
                      <div className="button_new_home banner">

                      </div>
                    </div>

                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>

        <div className="Inorbitverse_section">
          <div className="container">
            <div className="section">
              <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <GridItem sm={12} md={6} lg={6}>
                  <img src={require("../assets/images/section_2_img.png")} alt="logo" className="img-fluid robot_sec" />
                </GridItem>
                <GridItem sm={12} md={12} lg={6}>
                  <div className="Inorbitverse_content">
                    <span>Conceptenx </span>
                    <h2>What is Conceptenx Metaverse?</h2>
                    <p>The metaverse is a digital reality that combines aspects of Social Media, Online Gaming, Augmented Reality (AR), Virtual Reality (VR) and Crypto (Conceptenx) Communities to allow users to interact virtually.</p>
                    <p>Conceptenx Metaverse is combination of <span>Robot, DEX, NFT MarketPlace, Augmented reality Games</span> with <span>"Never Seen Before"</span> input into real-world settings to enhance the user experience.</p>


                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>


        <div className="Blockchain_Implementation">
          <div className="container">
            <div className="section">
              <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <GridItem xs={12} sm={12} md={12} lg={12} className="justify-content-center">
                  <span className="text-cener">Conceptenxverse</span>
                  <h1 className="main_title text-center">Multi Blockchain Implementation</h1>
                  <p>Conceptenx is first ever <span>Multi Blockchain & Multi Crypto</span> Earning Gaming DeFi Platform.<br />
                    Conceptenx Community will Earn Multiple Tokens as Rewards in the Future.</p>
                </GridItem>
              </GridContainer>
              <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <GridItem xs={12} sm={12} md={12} lg={10} className="">
                  <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                    <GridItem xs={12} sm={6} md={4} lg={3} className="">
                      <div className="icon_logo_c">
                        <img src={require("../assets/images/binance_logo.png")} alt="logo" className="img-fluid robot_sec" />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4} lg={3} className="">
                      <div className="icon_logo_c">
                        <img src={require("../assets/images/tron_logo.png")} alt="logo" className="img-fluid robot_sec" />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4} lg={3} className="">
                      <div className="icon_logo_c height_img">
                        <img src={require("../assets/images/trc_logo.png")} alt="logo" className="img-fluid robot_sec" />
                      </div>
                    </GridItem>
                    {/* <GridItem xs={12} sm={6} md={4} lg={3} className="">
                      <div className="icon_logo_c height_img ">
                        <img src={require("../assets/images/TOKEN_logo.png")} alt="logo" className="img-fluid robot_sec tran_inpur" />
                      </div>
                    </GridItem> */}
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>


        <div className="Inorbitverse_section">
          <div className="container">
            <div className="section p-0">
              <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">

                <GridItem sm={12} md={12} lg={6}>
                  <div className="Inorbitverse_content">
                    <h2>Power of NFT’s</h2>
                    <p>NFTs are becoming a Powerful Technology with<span>“Huge Earning Potential”</span>.</p>
                    <p>NFTs are designed to give you something that can’t be copied: ownership of the work (though the artist can still retain the copyright and reproduction rights, just like with physical artwork). To put it in terms of physical art collecting: anyone can buy a Monet print. But only one person can own the original.</p>
                  </div>
                </GridItem>
                <GridItem sm={12} md={6} lg={6}>
                  <img src={require("../assets/images/nft_img.png")} alt="logo" className="img-fluid robot_sec omg_center" />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>


        <div className="Blockchain_Implementation">
          <div className="container">
            <div className="section">
              <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <GridItem sm={12} md={12} lg={12} className="justify-content-center">
                  <span className="text-cener">Tokenomics</span>
                  <h1 className="main_title text-center">Token Distribution Model</h1>
                  <p>A complete Metaverse DeFi Gaming Dex NFT Token on "<span className="bold_span">Binance Blockchain</span>"</p>
                </GridItem>
                <GridItem sm={12} md={6} lg={6} className="justify-content-center">
                  <div className="width_content_e">
                    <p>Token Economics</p>
                    <ul>
                      <li>Total Supply 2 Billion Tokens</li>
                      <li>100% Liquidity Locked for a Year</li>
                      <li>50% Tokens Locked for Gaming and Cyborg Bot Reward</li>
                      <li>Strategic Burn on Every Transaction and Community</li>
                    </ul>
                    <p>Token Listing and Sale Prices</p>
                    <ul>
                      <li>Seed Sale :$ 0.05</li>
                      <li>Strategic Investment Sale : 0.7</li>
                      <li>Private sale: $ 0.08</li>
                      <li>IDO 1: $ 0.10</li>
                      <li>IEO : $ 0.12</li>
                      <li>Listing : $ 0.15</li>
                    </ul>
                  </div>
                </GridItem>
                <GridItem sm={12} md={6} lg={6} className="justify-content-center">
                  <img src={require("../assets/images/chart_45.png")} alt="Icon" className="chart_big_img" />
                </GridItem>
              </GridContainer>

            </div>
          </div>
        </div>
        <div className="global_dis_section">
          <div className="container">
            <div className="section">
              <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <GridItem sm={12} md={12} lg={12}>
                  <div className="card_sction_4">
                    <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                      <GridItem sm={6} md={4} lg={4}>
                        <div className="firt_text_section">
                          <p>CONCEPTENX REWARDS</p>
                          <h1>2X</h1>
                          <p>YOUR CAPITAL</p>
                        </div>
                      </GridItem>
                      <GridItem sm={6} md={4} lg={3}>
                        <div className="second_text_section">
                          <p>DAILY 0.6% TO 1%</p>
                          <h3>300 DAYS</h3>
                        </div>
                      </GridItem>
                      <GridItem sm={12} md={4} lg={5}>
                        <div className="third_text_section">
                          <img src={require("../assets/images/last_card_sect.png")} alt="Icon" className="chart_big_img" />
                        </div>
                      </GridItem>



                    </GridContainer>

                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>


        <div className="road_map_dis_section">
          <div className="container">
            <div className="section">
              <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <GridItem sm={12} md={12} lg={12} className="justify-content-center Blockchain_Implementation ">
                  <span className="text-cener">Roadmap</span>
                  <h1 className="main_title text-center">How it All Started</h1>
                </GridItem>
                <GridItem sm={12} md={12} lg={12}>
                  <img src={require("../assets/images/roadmap.png")} alt="Icon" className="chart_big_img" />

                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        {/* <div className="global_dis_section">
          <div className="container">
            <div className="section">
              <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <GridItem sm={12} md={12} lg={12}>
                  <div className="card_sction_4 card_sction_5">
                    <GridContainer className="" data-aos="fade-up" data-aos-duration="2000">
                      <GridItem sm={12} md={12} lg={6}>
                        <div className="firt_text_section">
                          <div className="card_meta_ bg_none">
                            <div>
                              <h3>Gameon</h3>
                              <p>A Unique and optional Huge Earning Opportunity to Earn Our Native Tokens on Every Referral you Make.</p>
                              <div className="button_new_home banner">
                                <Link to="/gameon"> <Button className="login">Play Now</Button></Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div> */}
        <FooterHome />
      </div>




      <div class="modal z-index_xx " id="myModal">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-body bg_text">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <div className="bg_text_clas_">
                <div>
                  <h1 className=""><span className="clas_text_gardiont">Hello</span> <br /> <span className="clas_text_gardiont">Conceptenx</span> <br /> <span className="clas_text_gardiont">Community</span></h1>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="new_font_color pt-3 pb-3">
                <p>We are Happy to Inform that on 14th Feb 2022 will Deploy Conceptenx <br /> verse Token on Binance Chain BSC Network.</p>
              </div>
              <div className="countdown">
                <Countdown
                  date={1647032400000}
                  renderer={renderer}
                />
              </div>
              <div className="new_font_color_second pt-3 pb-3">
                <p>Immediate to which the Blockchain Integration is Followed and  <br /> System Will be live <span>on  March 11th 2022</span> ending <br /> the<span> Registration Bonus Benefits forever.</span></p>
              </div>
              <div className="new_font_color_second pt-3 pb-3 botton_sectionsss">
                <p>Conceptenxverse Website Dev has been started and will be Live Soon <br /> after which Seed Sale of 0.05$ / Token Price Will be Ended</p>
              </div>
            </div>



          </div>
        </div>
      </div>


    </div>
  );
}
