import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import './index.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Provider } from "react-redux";
import store from "./store";


// pages for this product
import Home from "views/home.js";
import Login from "views/login/login.js";
import Register from "views/register/register.js";
import About from "views/about.js";
import FAQ from "views/faq.js";
import Dashboard from "views/dashboard.js";
import MyNetwork from "views/my-network.js";
import MyEarning from "views/my-earning.js";
import Staking from "views/staking.js";
import Withdraw from "views/withdraw.js";
import Achiever from "views/achiever.js";
import Profile from "views/profile.js";
import SupportTicket from "views/support-ticket.js";
import Gameon from "views/gameon.js";
import StakingList from "views/staking-list.js";

import AuthRoute from "./AuthRoute"

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <ToastContainer />
      <Switch>
        <Route path="/faq" component={FAQ} />
        {/* <AuthRoute path="/gameon" component={Gameon} /> */}
        <AuthRoute path="/support-ticket" component={SupportTicket} />
        <AuthRoute path="/profile" component={Profile} />
        <AuthRoute path="/achiever" component={Achiever} />
        <AuthRoute path="/withdraw" component={Withdraw} />
        <AuthRoute path="/staking" component={Staking} />
        <AuthRoute path="/staking-list" component={StakingList} />
        <AuthRoute path="/my-earning" component={MyEarning} />
        <AuthRoute path="/my-network" component={MyNetwork} />
        <Route path="/dashboard" component={Dashboard} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/activate/:activateid" component={Home} />
        <Route path="/register" component={Register} />
        <Route path="/login" component={Login} />
        <Route path="/" component={Home} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
