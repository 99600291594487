import jwtDecode from 'jwt-decode'

export function getAuth(accessToken) {

    var jwtToken = accessToken;
    var name = "Inorbit-";
    var token = jwtToken.replace(name, "");
    var decodedToken = jwtDecode(token);

    const currentTime = Date.now() / 1000
    var status = decodedToken.exp > currentTime;

    if (status) {
        return {
            name: decodedToken.name,
            id: decodedToken.id,
            isAuth: true
        }
    } else {
        return {
            name: "",
            id: "",
            isAuth: false
        }
    }

}