export async function connection() {

  var connect = {
    tronWeb: "",
    fullHost: "",
    address: ""
  };

  try {

    var tronCn = window.tronWeb;
    var address = window.tronWeb.defaultAddress.base58;
    var fullHost = (tronCn.trx && tronCn.trx.tronWeb && tronCn.trx.tronWeb.fullNode && tronCn.trx.tronWeb.fullNode.host) ?
      tronCn.trx.tronWeb.fullNode.host : "";

    connect.address = address;
    connect.fullHost = fullHost;
    connect.tronWeb = tronCn;

  } catch (err) {

  }

  return connect;
}
