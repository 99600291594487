import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import Countdown, { zeroPad } from "react-countdown";
import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import DashSideNavbar from "components/DashSideNavbar.js";
import { getProfile, getVolume } from "../Api/UserActions"
import { dateFormat } from "../helper/dateFormat"
import { toastAlert } from "../helper/toastAlert"
import { packageAmount } from "../helper/packages"
import config from "../config/config"
import ReactLoading from "react-loading";

import { connection } from "../helper/connection";
import { upgradeLevel } from "../Api/DashboardActions";
import SuccessModal from "../components/Modal/SuccessModal";

var frontUrl = config.frontUrl;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

var initialData = {
  link: "",
  userid: "",
  lastLogin: "",
  referalCount: 0,
  inorbitearned: 0,
  usdtearned: 0,
  currentPack: 0,
  nextPack: 0,
  roi: 0,
  inorbitbenefit: 0,
  referalearn: 0
}

export default function Dashboard(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const userData = useSelector((state) => state.authReducer);

  const [userInfo, setuserInfo] = useState(initialData);
  const [copytext, setcopytext] = React.useState(false);
  const [packageList, setpackageList] = React.useState(packageAmount);
  const [isPurchase, setisPurchase] = React.useState("");
  const [busineesvolume, setbusineesvolume] = React.useState(0);
  const [referalTimer, setreferalTimer] = React.useState({ status: false, timestamp: null });

  useEffect(() => {
    getProfileData();
    getVolumeData();
    if (localStorage.getItem("Congratulations") === "yes") {
      //window.$('#Congratulations').modal('show');
      localStorage.removeItem("Congratulations")
    }
  }, [userData]);

  async function getVolumeData() {
    if (userData && userData.id && userData.id !== "") {
      var { result, lastregister } = await getVolume({ id: userData.id });
      setbusineesvolume(result);
      if (lastregister) {
        var adddays = new Date(lastregister);
        adddays.setDate(adddays.getDate() + 5);
        var timestamp = Date.parse(new Date(adddays));
        setreferalTimer({ status: true, timestamp: timestamp })
      }


    }
  }

  async function getProfileData() {

    if (userData && userData.id && userData.id !== "") {
      var { result } = await getProfile({ id: userData.id });

      var addr = result.address.substring(0, 8);
      var addr1 = result.address.substring(30, 42);
      var concat = addr + "...." + addr1;

      var ref = frontUrl + "register#" + result.userid;
      var ref1 = frontUrl + "register#" + result.userid;

      var userid = result.userid;
      var loginDate = await dateFormat(result.lastLogin, "DD-MM-YYYY hh:mm");
      var childcount = result.childcount;
      var roi = (result.roi && result.roi.total) ?
        result.roi.total : 0;

      var currentpackage = result.currentpackage;
      var nextPack = parseFloat(currentpackage) + 1

      setuserInfo({
        link: ref,
        display: ref1,
        userid: userid,
        lastLogin: loginDate,
        referalCount: childcount,
        usdtearned: (result && result.totalUSDT > 0) ? result.totalUSDT.toFixed(2) : 0,
        inorbitearned: result.inorbittoken,
        currentPack: currentpackage,
        nextPack: nextPack,
        roi: roi,
        inorbitbenefit: (result.directreferaltoken && result.directreferaltoken.total) ?
          result.directreferaltoken.total : 0,
        referalearn: (result.directreferalamount && result.directreferalamount.total) ?
          result.directreferalamount.total : 0,
        inorbitbooster: (result.inorbitbooster && result.inorbitbooster.total) ?
          result.inorbitbooster.total : 0,
      })
    }
  }

  async function onCopy() {
    setcopytext(true);
    setTimeout(function () {
      setcopytext(false);
    }, 1000);
  }

  async function buyPackage(pack, amount) {

    var get = await connection();

    if (get && get.tronWeb && get.tronWeb !== "") {
      setisPurchase(pack)
      var tronWebConn = get.tronWeb;
      // if (get.fullHost !== config.fullHost) {
      //   setisPurchase("")
      //   toastAlert('error', "Please login  into tronlink mainet", 'tronlink');
      //   return;
      // }
      try {
        var checkLive = await tronWebConn.contract().at(config.InorbitContract);
      } catch (err) {
        setisPurchase("")
        toastAlert('error', "Please login  into tronlink mainet", 'tronlink');
        return;
      }


      var address = get.address;
      var contract = await tronWebConn.contract().at(config.USDT);
      var decimals = await contract.decimals().call();
      var allowance = await contract.allowance(address, config.InorbitContract).call();
      allowance = await tronWebConn.toDecimal(allowance._hex);;
      var buyAmt = parseFloat(amount) * (10 ** decimals);
      var balanceOf = await contract.balanceOf(address).call();
      var balance = balanceOf / 10 ** decimals;
      //console.log(allowance, 'allowanceallowance')
      var inorbitContract = await tronWebConn.contract().at(config.InorbitContract);

      if (allowance < buyAmt) {
        var approveAmt = 1000000 * 10 ** decimals;
        await contract.approve(config.InorbitContract, approveAmt).send({
          feeLimit: 1000000000,
          callValue: approveAmt,
          shouldPollResponse: false
        });
      }

      if (amount <= balance) {
        try {

          var inorbitContract = await tronWebConn.contract().at(config.InorbitContract);
          var result = await inorbitContract.activatePlan(pack, buyAmt).send({
            feeLimit: 1000000000,
            callValue: buyAmt,
            shouldPollResponse: false
          });

          var data = {
            pack: pack,
            address: address,
            txid: result,
            amount: amount
          }
          toastAlert('success', "Please wait you transaction is processing", 'purchased');
          var resp = await upgradeLevel(data);
          setisPurchase("")
          console.log(resp, 'resprespresp')
          if (resp && resp.message === "valid") {
            toastAlert('success', "Successfully upgraded", 'successpurchased');
            getProfileData();
          } else if (resp && resp.message === "invalid") {
            toastAlert('success', "Failed your transaction", 'successpurchased');
          } else {
            toastAlert('error', "Oops something went wrong.!", 'successpurchased');
          }
        } catch (err) {
          setisPurchase("")
        }

      } else {
        toastAlert('error', "Insuffucient balance", 'balance');
        setisPurchase("")
      }

    } else {
      setisPurchase("")
      toastAlert('error', "Please login into tronlink mainet", 'tronlink');
    }

  }


  // Countdown Timer
  const currentDate = new Date();
  const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">d</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">h</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">m</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">s</span></span>
      </div>
    );
  };

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksAfterlogin />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader dash_bg"}>
        <div className="container">
          <GridContainer>
            <GridItem sm={12} md={12} lg={3}>
              <DashSideNavbar />
            </GridItem>
            <GridItem sm={12} md={12} lg={9}>
              <div className="dash_right_setion">
                <div className="dash_box">
                  <h2 className="inner_title">Dashboard</h2>
                  <div className="dash_user_details">
                    <div>
                      <p>My User ID: <span>#{userInfo.userid}</span></p>
                      <p>My Affiliate ID:
                        <span>{userInfo.display}</span>
                        <CopyToClipboard text={userInfo.link} onCopy={() => onCopy()}>
                          <img src={require("../assets/images/copy_icon.png")} alt="Copy" className="ml-2" />
                        </CopyToClipboard>
                        {copytext &&
                          <p style={{ color: 'green', fontWeight: 600 }}>Copied</p>
                        }
                      </p>
                    </div>
                    <div>
                      {/* <p>My Total BV: <span>${(busineesvolume > 0) ? busineesvolume.toFixed(3) : 0}</span></p> */}
                      <p>Last Login: <span>{userInfo.lastLogin}</span></p>
                    </div>
                  </div>
                  <div className="dash_details_panel">
                    <div className="dash_details_panel_single">
                      <img src={require("../assets/images/icon_01.png")} alt="Copy" />
                      <h2>{userInfo.referalCount}</h2>
                      <h3>Direct Refferal</h3>
                    </div>
                    {/* <div className="dash_details_panel_single">
                      <img src={require("../assets/images/icon_02.png")} alt="Copy" />
                      <h2>{(userInfo.inorbitearned > 0) ? parseFloat(userInfo.inorbitearned).toFixed(2) : 0}</h2>
                      <h3>Earned Inorbit</h3>
                    </div> */}
                    <div className="dash_details_panel_single">
                      <img src={require("../assets/images/icon_03.png")} alt="Copy" />
                      <h2>{(userInfo.usdtearned > 0) ? parseFloat(userInfo.usdtearned).toFixed(2) : 0}</h2>
                      <h3>Earned USDT</h3>
                    </div>
                  </div>
                </div>

                <div className="dash_box">
                  <h2 className="inner_title">Package</h2>
                  <div className="package_details_panel">
                    {packageList && packageList.length > 0 && packageList.map((item, i) => {
                      var currentPack = parseFloat(userInfo.currentPack);
                      var level = parseFloat(item.level);
                      return (
                        <div className={(currentPack >= level) ? "package_details_panel_single" : "package_details_panel_single package_details_panel_single_disabled"}>

                          {isPurchase != "" && isPurchase == level ?
                            <div class="form-group button_new_home banner">
                              <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading" />
                            </div> :
                            <div>
                              <h3>Level {level}</h3>
                              <h2>{item.amount}</h2>
                            </div>
                          }
                          {level === userInfo.nextPack ?
                            <Button className="primary_btn primary_btn_absolute" onClick={() => { buyPackage(level, item.amount) }}>Activate</Button>
                            :
                            <Button className="primary_btn primary_btn_absolute" disabled={(currentPack >= level) ? false : true}>
                              {(currentPack >= level) ? "Activated" : "Activate"}
                            </Button>
                          }

                        </div>
                      )
                    })}
                  </div>
                </div>
                <GridContainer>
                  <GridItem md={8}>
                    <div className="dash_box roi_box">
                      <h2 className="inner_title">ROI</h2>
                      <div className="roi_details">
                        <div className="mb-3">
                          <img src={require("../assets/images/icon_03.png")} alt="Copy" />
                          <p>USDT Token</p>
                          <h3>{(userInfo.roi > 0) ? userInfo.roi.toFixed(2) : 0}</h3>
                        </div>
                      </div>
                    </div>
                  </GridItem>
                  {/* <GridItem md={4}>
                    <div className="gameon_bg">
                      <h2>Gameon</h2>
                      <h4>A Unique & optional Huge Earning Opportunity</h4>
                      <Button className="primary_btn"><Link to="/gameon">Play Now</Link></Button>
                    </div>
                  </GridItem> */}
                  <GridItem md={4}>
                    <div className="dash_benefits dash_benefits_middle">
                      <h3>Referral <br />Earned</h3>
                      <h2>{(userInfo.referalearn > 0) ? userInfo.referalearn.toFixed(2) : 0}</h2>
                      <p>USDT Token</p>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  {/* <GridItem md={4}>
                    <div className="dash_benefits dash_benefits_first">
                      <h3>Inorbit Benefits <br />Earned</h3>
                      <h2>{(userInfo.inorbitbenefit > 0) ? userInfo.inorbitbenefit.toFixed(2) : 0}</h2>
                      <p>Inorbit Token</p>
                    </div>
                  </GridItem> */}
                  {/* <GridItem md={4}>
                    <div className="dash_benefits dash_benefits_middle">
                      <h3>Referral <br />Earned</h3>
                      <h2>{(userInfo.referalearn > 0) ? userInfo.referalearn.toFixed(2) : 0}</h2>
                      <p>USDT Token</p>
                    </div>
                  </GridItem> */}
                  {/* <GridItem md={4}>
                    <div className="dash_benefits dash_benefits_first">
                      <div className="countdown_panel_div">
                        {referalTimer && referalTimer.status &&
                          <Countdown date={parseInt(referalTimer.timestamp)} renderer={renderer} />
                        }
                      </div>
                      <h3>Inorbit Booster <br />Earned</h3>
                      <h2>{userInfo.inorbitbooster}</h2>
                      <p>Inorbit Token</p>
                    </div>
                  </GridItem> */}
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
          <SuccessModal />
        </div>
      </div>
      <Footer />
    </div>
  );
}
