import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/home.js";


const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #24272c;
    font-size: 42px;
    background-color: #383b40;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > .minus {
    display: none;
    color: #24272c;
    font-size: 42px;
    background-color: #383b40;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function FAQ(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className="dash_top">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} className="text-center m-auto">
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="inner_title text-center">FREQUENTLY ASKED QUESTIONS</h2>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="faq_panel">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeFaq('panel1')} className="mt-5">
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <div className="accordian_head"><h2>What is Inorbit?</h2></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordian_para">
                      <p>Is a crowdfunding platform of the new generation and smart contract model that run on a 4 by 2 Matrixin the blockchain of TRON cryptocurrency. Our software algorithm performs the function of a distribution of the affiliate commissions between participantsthrough our model design. All transaction are in the public domain which can be verified on Tron blockchain.</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChangeFaq('panel2')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel2bh-content" id="panel2bh-header">
                    <div className="accordian_head"><h2>Who are the owners of Inorbit?</h2></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordian_para">
                      <p>Inorbit was created for the People. It does not have or require an owner or an admin. It is a self operating system located on the blockchain, that cannot be changed or altered. There are no Manager. This means that the platform is fully decentralized</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChangeFaq('panel3')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel3bh-content" id="panel3bh-header">
                    <div className="accordian_head"><h2>What is Tron?</h2></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordian_para">
                      <p>TRON (TRX) was created in 2017 and has become one of the leading cryptocurrencies. Tron is fast becoming one of the most reliable cryptocurrency as a large number of crypto enthusiastuse this currency.</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel4'} onChange={handleChangeFaq('panel4')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel4bh-content" id="panel4bh-header">
                    <div className="accordian_head"><h2>What is smart contract?</h2></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordian_para">
                      <p>This are codes in the blockchain cryptocurrencies. Which are called contract. They can control valuable digital assets.</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel5'} onChange={handleChangeFaq('panel5')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel5bh-content" id="panel4bh-header">
                    <div className="accordian_head"><h2>Are there hidden fees?</h2></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordian_para">
                      <p>Inorbit has no hidden fees. All income are distributed between participants</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel6'} onChange={handleChangeFaq('panel6')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel6bh-content" id="panel4bh-header">
                    <div className="accordian_head"><h2>How do participate in Inorbit?</h2></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordian_para">
                      <p>You can Participate in Inorbit with as little as 1500TRX by activating Pool 1</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel7'} onChange={handleChangeFaq('panel7')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel7bh-content" id="panel4bh-header">
                    <div className="accordian_head"><h2>How Many Pools do I have to complete?</h2></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordian_para">
                      <p>There a 7 Pools to be completed. At the completion of a pool the system automatically enrolls you the next Pool</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel8'} onChange={handleChangeFaq('panel8')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel8bh-content" id="panel4bh-header">
                    <div className="accordian_head"><h2>What is Pool recycling?</h2></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordian_para">
                      <p>Pool Recycling means that at the completion of a pool the system will also recycle you in the same pool you have completed. This is done to keep your income consistent.</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel9'} onChange={handleChangeFaq('panel9')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel9bh-content" id="panel4bh-header">
                    <div className="accordian_head"><h2>Can I activate the next pool before completing my existing Pool?</h2></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordian_para">
                      <p>All Pools are activated sequentially, you will need to completed your existing pool before enrolled to the next pool.</p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel10'} onChange={handleChangeFaq('panel10')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel10bh-content" id="panel4bh-header">
                    <div className="accordian_head"><h2>How Much can I earn?</h2></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordian_para">
                      <p>There are no limits to how much you can earn. We have created two types of income “Working income” and “None Working income”. Our model provides you unlimited earning possibilities</p>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
