export async function registerValidate(data) {

    var referalId = data.referalId;
    var dappaddress = data.dappaddress;

    var errors = {};
    if (referalId === "") {
        errors.referalId = "Please enter Sponsor wallet";
    }

    if (dappaddress === "") {
        errors.dappaddress = "Please enter Dapp addresss";
    }

    return errors;
}