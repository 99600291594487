import axios from "axios";
import config from "../config/config";

const baseUrl = config.baseUrl;

export const withdrawInorbitBenefit = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/withdraw-inorbit-benefit`,
            'data': data,
            'headers': {
                'authtoken': localStorage.getItem('accessToken')
            }
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
            txUrl: respData.data.txUrl,
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const withdrawInorbitBooster = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/withdraw-inorbit-booster`,
            'data': data,
            'headers': {
                'authtoken': localStorage.getItem('accessToken')
            }
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
            txUrl: respData.data.txUrl,
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const withdrawROI = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/withdraw-roi`,
            'data': data,
            'headers': {
                'authtoken': localStorage.getItem('accessToken')
            }
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
            txUrl: respData.data.txUrl,
            amount: respData.data.amount
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const withdrawReferal = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/withdraw-referal`,
            'data': data,
            'headers': {
                'authtoken': localStorage.getItem('accessToken')
            }
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
            txUrl: respData.data.txUrl,
            amount: respData.data.amount
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const withdrawLevelIncome = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/withdraw-level-income`,
            'data': data,
            'headers': {
                'authtoken': localStorage.getItem('accessToken')
            }
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
            txUrl: respData.data.txUrl,
            amount: respData.data.amount
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const withdrawPassedupIncome = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/withdraw-passedup-income`,
            'data': data,
            'headers': {
                'authtoken': localStorage.getItem('accessToken')
            }
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
            txUrl: respData.data.txUrl,
            amount: respData.data.amount
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const withdrawGameon = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/withdraw-gemeon`,
            'data': data,
            'headers': {
                'authtoken': localStorage.getItem('accessToken')
            }
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
            txUrl: respData.data.txUrl,
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const getUserWithdraw = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-user-withdraw`,
            'data': data,
            'headers': {
                'authtoken': localStorage.getItem('accessToken')
            }
        });
        return {
            result: respData.data.result,
            tokenPrice: respData.data.tokenPrice
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}
export const getMinimumwithdraw = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/get-withdraw-settings`,
            'headers': {
                'authtoken': localStorage.getItem('accessToken')
            }
        });
        return {
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}
function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        return err.response.data.errors;
    }
    else {
        return '';
    }
}


