import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { loginCheck } from '../../Api/UserActions';
import { toastAlert } from '../../helper/toastAlert';
import { getAuth } from '../../helper/getAuth';
import { AUTH_USER } from '../../constant/index'
import config from "../../config/config"
import { connection } from "../../helper/connection";

const useStyles = makeStyles(styles);

export default function Login(props) {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoad, setisLoad] = useState(false);

  async function submitLogin() {

    var get = await connection();
    var address = get.address;
    if (get && get.tronWeb && get.tronWeb !== "" && address !== "") {

      // if (get.fullHost !== config.fullHost) {
      //   toastAlert('error', "Please login  into tronlink mainet", 'tronlink');
      //   return;
      // }
      try {
        var tronWebConn = get.tronWeb;
        var checkLive = await tronWebConn.contract().at(config.InorbitContract);
      } catch (err) {
        toastAlert('error', "Please login  into tronlink mainet", 'tronlink');
        return;
      }

      setisLoad(true);
      var { message, token } = await loginCheck({ address: address });
      setisLoad(false);
      if (message === "invalid") {
        toastAlert('error', "Please register and continue", 'login');
      } else if (message === "blocked") {
        toastAlert('error', "Oops!, Please contact support team", 'login');
      } else if (message === "true") {
        localStorage.setItem('accessToken', token);
        toastAlert('success', "Successfully login", 'login');
        checkAuth(token);
        history.push('/dashboard');
      } else {
        toastAlert('error', "Please login into tronlink", 'tronlink');
      }

    } else {
      toastAlert('error', "Please login into tronlink", 'tronlink');
    }
  }

  async function checkAuth(token) {
    var response = await getAuth(token);
    dispatch({
      type: AUTH_USER,
      payload: response
    });
  }

  return (
    <div>
      <div className="auth_panel height_100vh">
        <div id='stars'></div>
        <div id='stars2'></div>
        <div id='stars3'></div>
        <div className={classes.container + " auth_padding_container"}>
          <GridContainer justify="center">
            <GridItem sm={12} md={10} lg={6} className="m-auto">
              <div className="text-center">
                <Link to="/"><img src={require("../../assets/images/logo.png")} alt="logo" className="img-fluid auth_logo" /></Link>
              </div>
              <form className="auth_form">
                <h1>Authenticate</h1>
                <div class="form-group button_new_home banner">
                  <Button disabled={isLoad} onClick={() => { submitLogin() }} data-toggle="modal" data-target="#Congratulations">Login</Button>
                </div>
                <p>Don't have an account yet?<Link to="/register" className="ml-2">Create an account</Link></p>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>


    </div>
  );
}
