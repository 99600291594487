import React, { useEffect, useState, Fragment } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/home.js";
import ReactDatatable from '@ashvin27/react-datatable';
import DashSideNavbar from "components/DashSideNavbar.js";
import WithdrawModal from "components/Modal/WithdrawModal";
import config from '../config/config';
import { dateFormat } from '../helper/dateFormat';
import { StakeHistory, ProceedUnstake } from "../Api/Myearning";
import $ from 'jquery'
import ReactLoading from "react-loading";
import { toastAlert } from '../helper/toastAlert';
import { Button } from '@material-ui/core';
const dashboardRoutes = [];

var tableConfig = {
  page_size: 6,
  length_menu: [5, 10, 20, 50]
}


const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function MyEarning(props) {
  const classes = useStyles();
  const { ...rest } = props;

  var roiColumns = [
    {
      key: "stakedate",
      text: "Stake Date",
      className: "stakedate",
      align: "left",
      sortable: false,
      cell: record => {
        var date = dateFormat(record.stakedate, 'DD-MM-YYYY hh:mm A');
        return date;
      }
    },
    {
      key: "days",
      text: "Locked Period",
      className: "days",
      sortable: false
    },
    {
      key: "stakedate",
      text: "Interest End Date",
      className: "stakedate",
      sortable: false,
      cell: record => {
        var days = parseFloat(record.days);
        var adddays = new Date(record.stakedate);
        adddays.setDate(adddays.getDate() + days);
        var date = dateFormat(adddays, 'DD-MM-YYYY hh:mm A');
        return date;
      }
    },
    {
      key: "stakeamount",
      text: "Stake Amount",
      className: "stakeamount",
      sortable: false
    },
    {
      key: "unstake",
      text: "Withdraw (USDT)",
      className: "unstake",
      sortable: false,
      cell: record => {
        var amount = (record.instantamount && record.instantamount.amount) ?
          parseFloat(record.instantamount.amount) : 0
        var status = (record.instantamount && record.instantamount.status) ?
          record.instantamount.status : "invalid"
        return (
          <Fragment>
            <p>{amount}</p>
            {/* {status === "pending" &&

              <Button
                id={'instantamount-' + record._id}
                onClick={() => withdrawStake(record._id, 'instantamount')}
                className="primary_btn">Withdraw</Button>
            } */}
            <div id={'instantamount-loading' + record._id} style={{ "display": "none" }} class="form-group button_new_home banner">
              <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading" />
            </div>

          </Fragment>
        );
      }
    },
    // {
    //   key: "unstake",
    //   text: "Withdraw (Inorbit)",
    //   className: "unstake",
    //   sortable: false,
    //   cell: record => {
    //     var amount = (record.instanttoken && record.instanttoken.amount) ?
    //       parseFloat(record.instanttoken.amount) : 0
    //     var status = (record.instanttoken && record.instanttoken.status) ?
    //       record.instanttoken.status : "invalid"
    //     return (
    //       <Fragment>
    //         <p>{amount}</p>
    //         {status === "pending" &&
    //           <button
    //             id={'instanttoken-' + record._id}
    //             className="btn btn-primary btn-sm"
    //             onClick={() => withdrawStake(record._id, 'instanttoken')}
    //             style={{ marginRight: '5px' }}>
    //             Withdraw
    //         </button>
    //         }
    //         <div id={'instanttoken-loading' + record._id} style={{ "display": "none" }} class="form-group button_new_home banner">
    //           <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading" />
    //         </div>

    //       </Fragment>
    //     );
    //   }
    // },
    {
      key: "unstake",
      text: "Bonus (USDT 150 or 300 days)",
      className: "unstake",
      sortable: false,
      cell: record => {
        var amount = (record.withdrawbonus && record.withdrawbonus.amount) ?
          parseFloat(record.withdrawbonus.amount) : 0
        var status = (record.withdrawbonus && record.withdrawbonus.status) ?
          record.withdrawbonus.status : "invalid"

        var days = parseFloat(record.days);
        var adddays = new Date(record.stakedate);
        adddays.setDate(adddays.getDate() + days);

        var currentDate = new Date();

        return (
          <Fragment>
            <p>{amount}</p>
            {/* {status === "pending" && currentDate > adddays &&
              <button
                id={'withdrawbonus-' + record._id}
                disabled={(currentDate > adddays) ? true : false}
                className="btn btn-primary btn-sm"
                onClick={() => withdrawStake(record._id, 'withdrawbonus')}
                style={{ marginRight: '5px' }}>
                Withdraw
            </button>
            } */}
            <div id={'withdrawbonus-loading' + record._id} style={{ "display": "none" }} class="form-group button_new_home banner">
              <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading" />
            </div>
          </Fragment>
        );
      }
    }

  ];




  const [List, setList] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [txurl, settxurl] = useState("");

  useEffect(() => {

    getRoiList();

  }, []);

  async function getRoiList(_id, type) {

    var { result } = await StakeHistory()
    setList(result)
    setisLoad(true)
  }

  async function withdrawStake(id, type) {

    setisLoad(false)
    var idVal = id.toString()
    var index = List.findIndex(val => val._id.toString() === id.toString());
    if (index !== -1) {
      $('#' + type + "-" + idVal).css("display", "none")
      $('#' + type + "-loading" + idVal).css("display", "block")
      List[index][type].status = "withdraw";
      setList(List)
      setisLoad(true)
      var { status, txUrl } = await ProceedUnstake({ id: id, withdrawType: type });

      settxurl(txUrl);
      window.$('#Congratulations').modal('show');

      $('#' + type + "-loading" + idVal).css("display", "none")
      if (status && status === 200) {
        toastAlert('success', "Successfully withdraw", 'register');
      } else {
        toastAlert('error', "Oops something went wrong.!", 'register');
      }
    }

  }

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksAfterlogin />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader dash_bg_odd"}>
        <div className="container">
          <GridContainer>
            <GridItem sm={12} md={12} lg={3}>
              <DashSideNavbar />
            </GridItem>
            <GridItem sm={12} md={12} lg={9}>
              <div className="dash_right_setion">
                <div className="dash_box">
                  <h2 className="inner_title">Staking List</h2>

                  <div class="tab-content mt-4" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-roi" role="tabpanel" aria-labelledby="nav-roi-tab">
                      <div className="primary_table_div">
                        <div className="table-responsive">
                          {isLoad && <ReactDatatable
                            config={tableConfig}
                            records={List}
                            columns={roiColumns}
                          />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {txurl && txurl !== "" &&
        <WithdrawModal txurl={txurl} />
      }
      <Footer />
    </div>
  );
}