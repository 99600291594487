import React from 'react'
const SuccessModal = (props) => {

    return (
        <div>
            <div id="Congratulations" class="modal fade" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header pt-0">
                            <button type="button" class="" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <div className="firt_text_section ">
                                <p>Congratulations for </p>
                                <h1>$ 5</h1>
                                <p>Worth Inorbit Privatesale Whitelist</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default SuccessModal;