import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/home.js";
import CopyToClipboard from "react-copy-to-clipboard";

import DashSideNavbar from "components/DashSideNavbar.js";

import { getProfile, getNetwork, getVolume } from "../Api/UserActions"
import { dateFormat } from "../helper/dateFormat"
import config from "../config/config"

var frontUrl = config.frontUrl;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

var initialData = {
  link: "",
  userid: "",
  lastLogin: ""
}

export default function MyNetwork(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const userData = useSelector((state) => state.authReducer);
  const [userInfo, setuserInfo] = useState(initialData);
  const [copytext, setcopytext] = React.useState(false);
  const [networkList, setnetworkList] = React.useState([]);
  const [userId, setuserId] = React.useState('-');
  const [searchTxt, setsearchTxt] = React.useState('');
  const [busineesvolume, setbusineesvolume] = React.useState(0);

  useEffect(() => {
    getProfileData();
    getVolumeData();
    getMyNetwork(userData.id);
  }, [userData]);

  async function getVolumeData() {
    if (userData && userData.id && userData.id !== "") {
      var { result } = await getVolume({ id: userData.id });
      setbusineesvolume(result)
    }
  }

  async function getProfileData() {
    if (userData && userData.id && userData.id !== "") {
      var { result } = await getProfile({ id: userData.id });

      var addr = result.address.substring(0, 8);
      var addr1 = result.address.substring(30, 42);
      var concat = addr + "...." + addr1;

      var ref = frontUrl + "register#" + result.userid;
      var ref1 = frontUrl + "register#" + result.userid;

      var userid = result.userid;
      var loginDate = await dateFormat(result.lastLogin, "DD-MM-YYYY hh:mm");
      setuserInfo({
        link: ref,
        display: ref1,
        userid: userid,
        lastLogin: loginDate,
      })
    }
  }

  async function getMyNetwork(id) {

    if (id && id !== "") {
      var { result, userid } = await getNetwork({ userid: id });
      setnetworkList(result);
      setuserId(userid);
      console.log(result, 'result');
    }


  }

  async function onCopy() {
    setcopytext(true);
    setTimeout(function () {
      setcopytext(false);
    }, 1000);
  }

  const inputChange = async (event) => {
    var value = event.target.value;
    var index = networkList.findIndex(val => val.userid.toString() === value.toString());
    if (index !== -1 && value !== "") {
      setsearchTxt(networkList[index]._id)
    } else if (value === "") {
      setsearchTxt("")
    }
  }

  const searchNetwork = async (event) => {
    if (searchTxt && searchTxt !== "") {
      getMyNetwork(searchTxt);
    } else {
      getMyNetwork(userData.id);
    }

  }

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksAfterlogin />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader dash_bg_odd"}>
        <div className="container">
          <GridContainer>
            <GridItem sm={12} md={12} lg={3}>
              <DashSideNavbar />
            </GridItem>
            <GridItem sm={12} md={12} lg={9}>
              <div className="dash_right_setion">
                <div className="dash_box">
                  <h2 className="inner_title">My Network</h2>
                  <div className="dash_user_details">
                    <div>
                      <p>My User ID: <span>#{userInfo.userid}</span></p>
                      <p>My Affiliate ID:
                        <span>{userInfo.display}</span>
                        <CopyToClipboard text={userInfo.link} onCopy={() => onCopy()}>
                          <img src={require("../assets/images/copy_icon.png")} alt="Copy" className="ml-2" />
                        </CopyToClipboard>
                        {copytext &&
                          <p style={{ color: 'green', fontWeight: 600 }}>Copied</p>
                        }
                      </p>
                    </div>
                    <div>
                      <p>My Total BV: <span>${(busineesvolume > 0) ? busineesvolume.toFixed(2) : 0}</span></p>
                      <p>Last Login: <span>{userInfo.lastLogin}</span></p>
                    </div>
                  </div>
                  <div className="dash_search">
                    <input
                      type="text"
                      onChange={inputChange}
                      placeholder="Search by id"
                      className="primary_input"
                    />
                    <Button type="button" onClick={(event) => { searchNetwork(event) }} className="primary_btn">Search</Button>
                  </div>
                  <div className="plan_img">
                    <div className="plan_pool_head network_box">
                      <h4>ID {userId}</h4>
                      {/* <h2>5</h2> */}
                    </div>

                    {/* First Row */}
                    <div className="plan_pool_first_row">
                      {networkList && networkList.length > 0 && networkList.map((item, i) => {
                        return (
                          <div className="plan_pool_head_child network_box">
                            <h4>ID {item.userid}</h4>
                            {/* <h2>5</h2> */}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
