
export async function Validate(data) {

    var errors = {};

    var issuestype = data.issuestype;
    var email = data.email;
    var description = data.description;


    const regx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var validEmail = regx.test(String(email).toLowerCase());

    if (issuestype === "") {
        errors.issuestype = "Select your issue";
    }

    if (email === "") {
        errors.email = "Please enter email id";
    } else if (!validEmail) {
        errors.email = "Please enter valid email id";
    }

    if (description === "") {
        errors.description = "Please enter description";
    }
    return errors;
}

export async function ValidateReply(data) {

    var errors = {};

    var replymsg = data;

    if (replymsg === "") {
        errors.replymsg = "Please enter message";
    }

    return errors;
}