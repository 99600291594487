import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';

import { AUTH_USER } from '../constant/index'

export default function DashSideNavbar(props) {

  const history = useHistory();
  const dispatch = useDispatch();

  window.addEventListener('message', function (e) {
    try {
      if (e.data.message && e.data.message.action === "setAccount") {
        logout();
      }
    } catch (e) {
      console.log('wallet error', e)
    }
  });

  async function logout() {
    var data = {
      name: "",
      id: "",
      isAuth: false
    }
    localStorage.removeItem('accessToken');
    dispatch({
      type: AUTH_USER,
      payload: data
    });
    history.push('/login');
  }

  return (
    <div className="dash_left_setion">
      <ul className="dash_menu">
        <li><NavLink to="/dashboard">Dashboard</NavLink></li>
        <li><NavLink to="/my-network">My Network</NavLink></li>
        <li><NavLink to="/my-earning">My Earning</NavLink></li>
        <li><NavLink to="/staking">Staking</NavLink></li>
        <li><NavLink to="/staking-list">Staking List</NavLink></li>
        {/* <li><NavLink to="/gameon">Gameon</NavLink></li> */}
        <li><NavLink to="/withdraw">Withdraw</NavLink></li>
        <li><NavLink to="/achiever">Achiever</NavLink></li>
        <li><NavLink to="/support-ticket">Support Ticket</NavLink></li>
        {/* <li><NavLink to="/news">News & Update</NavLink></li> */}
        <li style={{ 'cursor': 'pointer' }} onClick={() => { logout() }}>Logout</li>
      </ul>
    </div>
  );
}
