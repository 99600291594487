import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];
export default function Login(props) {

  const { ...rest } = props;
  const classes = useStyles();
  return (
    <div>
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <div className="auth_panel">
        <div id='stars'></div>
        <div id='stars2'></div>
        <div id='stars3'></div>
        <div className="glass_section_bg">
          <div className={classes.container + " auth_padding_container"}>
            <img src={require("../assets/images/rocket.png")} alt="logo" className="img-fluid log_section_rocket" />
            <GridContainer justify="center">
              <GridItem sm={12} md={10} lg={6} >
                <img src={require("../assets/images/flagimg.png")} alt="logo" className="img-fluid" />

              </GridItem>
              <GridItem sm={12} md={10} lg={6} >
                <div className="Inorbitverse_content">
                  <span>About Us</span>
                  <h2 className="mt-0">Conceptenx Metaverse</h2>
                  <p className="mb-5">We Believe in Decentralisation and Our aim is to Create a <span> Metaverse </span> for Our Community.</p>
                  <p>We have Built this Platform with Love from the People of <span>"PANAMA"</span>. We are Part of <span>Central America</span>.</p>
                </div>

              </GridItem>
              <GridItem sm={12} md={10} lg={12} >
                <div className="Inorbitverse_content">
                  <p className="mb-5">Panama's announcement came just a day after EI Salvador's "Bitcoin Day" when they officially began allowing residents to use Bitcoin as legal Tender... <span>We are the Next "El Salvador's"</span>.</p>
                  <p className="mb-5">We are the World's First Crowdfunding and DEX with Robot in the field of Blockchain on Metaverse.</p>
                  <p className="mb-5">Yes, you Heard it Right.... It's Happening and you are part of <span>"Conceptenx Metaverse"</span></p>


                  <p>We are not Just a Team or Individuals - <span> We are a Metaverse Blockchain and AI Community</span>.</p>
                </div>

              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>


    </div>
  );
}
