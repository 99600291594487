import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/home.js";
import ReactLoading from "react-loading";
import DashSideNavbar from "components/DashSideNavbar.js";
import WithdrawModal from "components/Modal/WithdrawModal";
import { toastAlert } from '../helper/toastAlert';
import { useHistory } from "react-router-dom";
import {
  getUserWithdraw,
  withdrawInorbitBenefit,
  withdrawInorbitBooster,
  withdrawROI,
  withdrawReferal,
  withdrawLevelIncome,
  withdrawPassedupIncome,
  withdrawGameon,
  getMinimumwithdraw
} from "../Api/WithdrawActions"

import {
  numberFloatOnly
} from "../helper/custommath"

import config from "../config/config"

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Withdraw(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const history = useHistory();

  const userData = useSelector((state) => state.authReducer);

  const [inorbitbenefit, setinorbitbenefit] = React.useState({ total: 0, available: 0 });
  const [inorbitbooster, setinorbitbooster] = React.useState({ total: 0, available: 0 });
  const [roi, setroi] = React.useState({ total: 0, available: 0 });
  const [referal, setreferal] = React.useState({ total: 0, available: 0 });
  const [levelincome, setlevelincome] = React.useState({ total: 0, available: 0 });
  const [passedup, setpassedup] = React.useState({ total: 0, available: 0 });
  const [gameon, setgameon] = React.useState({ total: 0, available: 0 });
  const [privatesale, setprivatesale] = React.useState({ total: 0, available: 0 });
  const [minwithdraw, setminwithdraw] = React.useState({ token: 0, amount: 0 });

  const [isLoad, setisLoad] = React.useState(false);
  const [isLoad1, setisLoad1] = React.useState(false);
  const [isLoad2, setisLoad2] = React.useState(false);
  const [isLoad3, setisLoad3] = React.useState(false);
  const [isLoad4, setisLoad4] = React.useState(false);
  const [isLoad5, setisLoad5] = React.useState(false);
  const [isLoad6, setisLoad6] = React.useState(false);
  const [isLoad7, setisLoad7] = React.useState(false);

  const [gainwithdraw, setgainwithdraw] = React.useState({
    inorbitbenibit: "",
    inorbitboster: "",
    roi: "",
    referal: "",
    levelincome: "",
    passedup: "",
    gameon: "",
    privatesale: "",
  });

  const [errors, seterrors] = React.useState({});
  const [txurl, settxurl] = useState("");

  useEffect(() => {
    withdrawDetails();
    getSettings();
  }, [userData]);

  async function getSettings() {

    var { result } = await getMinimumwithdraw();
    console.log(result, 'resultresultresult')
    setminwithdraw({
      token: (result && result.minwithdrawtoken) ? result.minwithdrawtoken : 0,
      amount: (result && result.minwithdrawusdt) ? result.minwithdrawusdt : 0,
      tokenfee: (result && result.withdrawfeeinorbit) ? result.withdrawfeeinorbit : 0,
      amountfee: (result && result.withdrawfeeusdt) ? result.withdrawfeeusdt : 0,
    })

  }


  async function withdrawDetails() {
    if (userData && userData.id && userData.id !== "") {
      var { result } = await getUserWithdraw();
      setinorbitbenefit({
        total: (result && result.directreferaltoken && result.directreferaltoken.total) ?
          result.directreferaltoken.total : 0,
        available: (result && result.directreferaltoken && result.directreferaltoken.available) ?
          result.directreferaltoken.available : 0
      });

      setinorbitbooster({
        total: (result && result.inorbitbooster && result.inorbitbooster.total) ?
          result.inorbitbooster.total : 0,
        available: (result && result.inorbitbooster && result.inorbitbooster.available) ?
          result.inorbitbooster.available : 0
      });

      setroi({
        total: (result && result.roi && result.roi.total) ?
          result.roi.total : 0,
        available: (result && result.roi && result.roi.available) ?
          result.roi.available : 0
      });

      setreferal({
        total: (result && result.directreferalamount && result.directreferalamount.total) ?
          result.directreferalamount.total : 0,
        available: (result && result.directreferalamount && result.directreferalamount.available) ?
          result.directreferalamount.available : 0
      });

      setlevelincome({
        total: (result && result.levelincome && result.levelincome.total) ?
          result.levelincome.total : 0,
        available: (result && result.levelincome && result.levelincome.available) ?
          result.levelincome.available : 0
      });

      setpassedup({
        total: (result && result.passedup && result.passedup.total) ?
          result.passedup.total : 0,
        available: (result && result.passedup && result.passedup.available) ?
          result.passedup.available : 0
      });

      setgameon({
        total: (result && result.gameon && result.gameon.total) ?
          result.gameon.total : 0,
        available: (result && result.gameon && result.gameon.available) ?
          result.gameon.available : 0
      });

      setgameon({
        total: (result && result.gameon && result.gameon.total) ?
          result.gameon.total : 0,
        available: (result && result.gameon && result.gameon.available) ?
          result.gameon.available : 0
      });

      setprivatesale({
        total: (result && result.privatesaletoken && result.privatesaletoken.total) ?
          result.privatesaletoken.total : 0,
        available: (result && result.privatesaletoken && result.privatesaletoken.available) ?
          result.privatesaletoken.available : 0
      });

    }

  }

  async function getInorbitBenefit() {

    seterrors({})
    if (parseFloat(inorbitbenefit.available) > 0) {

      var amt = parseFloat(gainwithdraw.inorbitbenibit);
      if (amt > parseFloat(inorbitbenefit.available)) {
        seterrors({ "inorbitbenibit": "Insufficient balance" });
        return;
      }
      if (amt > 0) {
        setisLoad(true)
        var { status, message, txUrl } = await withdrawInorbitBenefit({ amount: amt });
        if (status === 200) {
          setgainwithdraw({ inorbitbenibit: "" });
          toastAlert('success', "Successfully withdraw", 'inorbeni');
          withdrawDetails();
          settxurl(txUrl);
          window.$('#Congratulations').modal('show');
        } else if (status === 400 && message === "invalidaddress") {
          toastAlert('error', "Please update bep20 address", 'inorbeni');
          history.push('/profile');
        } else if (status === 400 && message === "Insufficient") {
          toastAlert('success', "Insufficient balance", 'inorbeni');
        } else {
          toastAlert('success', "Oops something went wrong.!", 'inorbeni');
        }

      } else {
        seterrors({ "inorbitbenibit": "Enter valid amount" });
        return;
      }
      setisLoad(false)
    } else {
      toastAlert('error', "Insufficient balance", 'inorbeni');
    }
  }

  async function getInorbitBooster() {

    seterrors({})
    if (parseFloat(inorbitbooster.available) > 0) {

      var amt = parseFloat(gainwithdraw.inorbitboster);
      if (amt > parseFloat(inorbitbooster.available)) {
        seterrors({ "inorbitboster": "Insufficient balance" });
        return;
      }
      if (amt > 0) {
        setisLoad1(true)
        var { status, message, txUrl } = await withdrawInorbitBooster({ amount: amt });
        if (status === 200) {
          setgainwithdraw({ inorbitboster: "" })
          toastAlert('success', "Successfully withdraw", 'inorbeni');
          withdrawDetails();
          settxurl(txUrl);
          window.$('#Congratulations').modal('show');
        } else if (status === 400 && message === "invalidaddress") {
          toastAlert('error', "Please update bep20 address", 'inorbeni');
          history.push('/profile');
        } else if (status === 400 && message === "Insufficient") {
          toastAlert('success', "Insufficient balance", 'inorbeni');
        } else {
          toastAlert('success', "Oops something went wrong.!", 'inorbeni');
        }

      } else {
        seterrors({ "inorbitboster": "Enter valid amount" });
        return;
      }
      setisLoad1(false)
    } else {
      toastAlert('error', "Insufficient balance", 'inorbeni');
    }
  }

  async function getROI() {

    seterrors({})
    if (parseFloat(roi.available) > 0) {

      var amt = parseFloat(gainwithdraw.roi);
      if (amt > parseFloat(roi.available)) {
        seterrors({ "inorbitboster": "Insufficient balance" });
        return;
      }
      if (amt > 0) {
        setisLoad2(true)
        var { status, message, txUrl, amount } = await withdrawROI({ amount: amt });
        if (status === 200) {
          setgainwithdraw({ roi: "" })
          toastAlert('success', "Successfully withdraw", 'inorbeni');
          withdrawDetails();
          settxurl(txUrl);
          window.$('#Congratulations').modal('show');
        } else if (status === 400 && message === "invalidaddress") {
          toastAlert('error', "Please update trc20 address", 'inorbeni');
          history.push('/profile');
        } else if (status === 400 && message === "minimumwithdraw") {
          toastAlert('error', "Minimum withdraw " + amount + " USDT", 'inorbeni');
        } else if (status === 400 && message === "Insufficient") {
          toastAlert('success', "Insufficient balance", 'inorbeni');
        } else {
          toastAlert('success', "Oops something went wrong.!", 'inorbeni');
        }

      } else {
        seterrors({ "inorbitbenibit": "Enter valid amount" });
        return;
      }
      setisLoad2(false)
    } else {
      toastAlert('error', "Insufficient balance", 'inorbeni');
    }
  }

  async function getReferal() {

    seterrors({})
    if (parseFloat(referal.available) > 0) {

      var amt = parseFloat(gainwithdraw.referal);
      if (amt > parseFloat(referal.available)) {
        seterrors({ "referal": "Insufficient balance" });
        return;
      }
      if (amt > 0) {
        setisLoad3(true)
        var { status, message, txUrl, amount } = await withdrawReferal({ amount: amt });
        if (status === 200) {
          setgainwithdraw({ referal: "" })
          toastAlert('success', "Successfully withdraw", 'inorbeni');
          withdrawDetails();
          settxurl(txUrl);
          window.$('#Congratulations').modal('show');
        } else if (status === 400 && message === "invalidaddress") {
          toastAlert('error', "Please update trc20 address", 'inorbeni');
          history.push('/profile');
        } else if (status === 400 && message === "minimumwithdraw") {
          toastAlert('error', "Minimum withdraw " + amount + " USDT", 'inorbeni');
        } else if (status === 400 && message === "Insufficient") {
          toastAlert('success', "Insufficient balance", 'inorbeni');
        } else {
          toastAlert('success', "Oops something went wrong.!", 'inorbeni');
        }

      } else {
        seterrors({ "referal": "Enter valid amount" });
        return;
      }
      setisLoad3(false)
    } else {
      toastAlert('error', "Insufficient balance", 'inorbeni');
    }
  }

  async function getLevelIncome() {
    // return;
    seterrors({})
    if (parseFloat(levelincome.available) > 0) {

      var amt = parseFloat(gainwithdraw.levelincome);
      if (amt > parseFloat(levelincome.available)) {
        seterrors({ "levelincome": "Insufficient balance" });
        return;
      }
      if (amt > 0) {
        setisLoad4(true)
        var { status, message, txUrl, amount } = await withdrawLevelIncome({ amount: amt });
        if (status === 200) {
          setgainwithdraw({ levelincome: "" })
          toastAlert('success', "Successfully withdraw", 'inorbeni');
          withdrawDetails();
          settxurl(txUrl);
          window.$('#Congratulations').modal('show');
        } else if (status === 400 && message === "invalidaddress") {
          toastAlert('error', "Please update trc20 address", 'inorbeni');
          history.push('/profile');
        } else if (status === 400 && message === "minimumwithdraw") {
          toastAlert('error', "Minimum withdraw " + amount + " USDT", 'inorbeni');
        } else if (status === 400 && message === "Insufficient") {
          toastAlert('success', "Insufficient balance", 'inorbeni');
        } else {
          toastAlert('success', "Oops something went wrong.!", 'inorbeni');
        }

      } else {
        seterrors({ "levelincome": "Enter valid amount" });
        return;
      }
      setisLoad4(false)
    } else {
      toastAlert('error', "Insufficient balance", 'inorbeni');
    }
  }

  async function getPassedupIncome() {
    // return;
    seterrors({})
    if (parseFloat(passedup.available) > 0) {

      var amt = parseFloat(gainwithdraw.passedup);
      if (amt > parseFloat(passedup.available)) {
        seterrors({ "passedup": "Insufficient balance" });
        return;
      }
      if (amt > 0) {
        setisLoad5(true)
        var { status, message, txUrl, amount } = await withdrawPassedupIncome({ amount: amt });
        if (status === 200) {
          setgainwithdraw({ passedup: "" })
          toastAlert('success', "Successfully withdraw", 'inorbeni');
          withdrawDetails();
          settxurl(txUrl);
          window.$('#Congratulations').modal('show');
        } else if (status === 400 && message === "invalidaddress") {
          toastAlert('error', "Please update trc20 address", 'inorbeni');
          history.push('/profile');
        } else if (status === 400 && message === "minimumwithdraw") {
          toastAlert('error', "Minimum withdraw " + amount + " USDT", 'inorbeni');
        } else if (status === 400 && message === "Insufficient") {
          toastAlert('success', "Insufficient balance", 'inorbeni');
        } else {
          toastAlert('success', "Oops something went wrong.!", 'inorbeni');
        }

      } else {
        seterrors({ "passedupincome": "Enter valid amount" });
        return;
      }
      setisLoad5(false)
    } else {
      toastAlert('error', "Insufficient balance", 'inorbeni');
    }
  }

  async function getGameonIncome() {

    seterrors({})
    if (parseFloat(gameon.available) > 0) {

      var amt = parseFloat(gainwithdraw.gameon);
      if (amt > parseFloat(gameon.available)) {
        seterrors({ "gameon": "Insufficient balance" });
        return;
      }
      if (amt > 0) {
        setisLoad6(true)
        var { status, message, txUrl } = await withdrawGameon({ amount: amt });
        if (status === 200) {
          setgainwithdraw({ gameon: "" })
          toastAlert('success', "Successfully withdraw", 'inorbeni');
          withdrawDetails();
          settxurl(txUrl);
          window.$('#Congratulations').modal('show');
        } else if (status === 400 && message === "invalidaddress") {
          toastAlert('error', "Please update trc20 address", 'inorbeni');
          history.push('/profile');
        } else if (status === 400 && message === "Insufficient") {
          toastAlert('success', "Insufficient balance", 'inorbeni');
        } else {
          toastAlert('success', "Oops something went wrong.!", 'inorbeni');
        }

      } else {
        seterrors({ "gameon": "Enter valid amount" });
        return;
      }
      setisLoad6(false)
    } else {
      toastAlert('error', "Insufficient balance", 'inorbeni');
    }
  }


  const inputChange = async (event) => {

    var id = event.target.id;
    var value = event.target.value;

    var status = await numberFloatOnly(value);

    setgainwithdraw({
      inorbitbenibit: "",
      inorbitboster: "",
      roi: "",
      referal: "",
      levelincome: "",
      passedup: ""
    });

    seterrors({});
    if (!status) {
      seterrors({ [id]: "Enter valid amount" });
      return;
    }
    if (status) {
      setgainwithdraw({ [id]: value });
    }

  }

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksAfterlogin />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader dash_bg"}>
        <div className="container">
          <GridContainer>
            <GridItem sm={12} md={12} lg={3}>
              <DashSideNavbar />
            </GridItem>
            <GridItem sm={12} md={12} lg={9}>
              <div className="dash_right_setion">
                <div className="dash_box">
                  <h2 className="inner_title">Withdraw</h2>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Minimum withdraw USDT : {minwithdraw.amount}</h5>
                      <h5>Withdraw fee USDT : {minwithdraw.amountfee}%</h5>
                    </div>
                    {/* <div>
                      <h5>Minimum withdraw Inorbit : {minwithdraw.token}</h5>
                      <h5>Withdraw fee Inorbit : {minwithdraw.tokenfee}%</h5>
                    </div> */}
                  </div>
                  <GridContainer>
                    {/* <GridItem md={6}>
                      <div className="withdraw_box">
                        <div className="withdraw_box_top">
                          <h2>Inorbit Benefits Earned</h2>
                          <p>Total Inorbit Token Earned</p>
                          <div>
                            <img src={require("../assets/images/icon_large_01.png")} alt="Icon" className="withdraw_inorbit_icon" />
                            <h3>{(inorbitbenefit.total > 0) ? inorbitbenefit.total.toFixed(2) : 0}</h3>
                          </div>
                          <p>Available Inorbit Token</p>
                          <div>
                            <img src={require("../assets/images/icon_large_01.png")} alt="Icon" className="withdraw_inorbit_icon" />
                            <h3>{(inorbitbenefit.available > 0) ? inorbitbenefit.available.toFixed(2) : 0}</h3>
                          </div>
                        </div>
                        <div className="withdraw_box_bottom withdraw_box_bottom_disabled">
                          <label>Enter the amount to withdraw</label>
                          <div>
                            <input
                              onChange={inputChange}
                              id="inorbitbenibit"
                              type="text"
                              className="primary_input"
                              placeholder="Enter amount"
                              value={gainwithdraw.inorbitbenibit}
                            />
                            <Button
                              disabled={(parseFloat(inorbitbenefit.available) > 0) ? false : (isLoad ? isLoad : false)}
                              onClick={() => getInorbitBenefit()}
                              className="primary_btn">Withdraw</Button>

                          </div>
                          {errors && errors.inorbitbenibit && errors.inorbitbenibit !== "" &&
                            <p style={{ color: 'red' }}>{errors.inorbitbenibit}</p>
                          }
                          {isLoad &&
                            <div class="form-group button_new_home banner">
                              <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading" />
                            </div>
                          }
                        </div>
                      </div>
                    </GridItem> */}
                    {/* <GridItem md={6}>
                      <div className="withdraw_box">
                        <div className="withdraw_box_top">
                          <h2>Inorbit Booster Earned</h2>
                          <p>Total Inorbit Token Earned</p>
                          <div>
                            <img src={require("../assets/images/icon_large_01.png")} alt="Icon" className="withdraw_inorbit_icon" />
                            <h3>{(inorbitbooster.total > 0) ? inorbitbooster.total.toFixed(2) : 0}</h3>
                          </div>
                          <p>Available Inorbit Token</p>
                          <div>
                            <img src={require("../assets/images/icon_large_01.png")} alt="Icon" className="withdraw_inorbit_icon" />
                            <h3>{(inorbitbooster.available > 0) ? inorbitbooster.available.toFixed(2) : 0}</h3>
                          </div>
                        </div>
                        <div className="withdraw_box_bottom withdraw_box_bottom_disabled">
                          <label>Enter the amount to withdraw</label>
                          <div>
                            <input
                              onChange={inputChange}
                              id="inorbitboster"
                              placeholder="Enter amount"
                              type="text"
                              className="primary_input"
                              value={gainwithdraw.inorbitboster}
                            />

                            <Button
                              disabled={(parseFloat(inorbitbooster.available) > 0) ? false : (isLoad1 ? isLoad1 : false)}
                              className="primary_btn"
                              onClick={() => getInorbitBooster()}
                            >Withdraw</Button>
                          </div>
                          {errors && errors.inorbitboster && errors.inorbitboster !== "" &&
                            <p style={{ color: 'red' }}>{errors.inorbitboster}</p>
                          }
                          {isLoad1 &&
                            <div class="form-group button_new_home banner">
                              <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading" />
                            </div>
                          }
                        </div>
                      </div>
                    </GridItem> */}
                    <GridItem md={6}>
                      <div className="withdraw_box">
                        <div className="withdraw_box_top">
                          <div className="withdraw_headerflex">
                            <h2>ROI</h2>
                            <div>
                              {/* <p className="mb-0">Inorbit Token</p> */}
                              <p className="mb-0 active">USDT</p>
                            </div>
                          </div>
                          <p>Total <span>USDT</span> Earned</p>
                          <div>
                            <img src={require("../assets/images/icon_large_02.png")} alt="Icon" className="withdraw_usdt_icon" />
                            <h3>{(roi.total > 0) ? roi.total.toFixed(2) : 0}</h3>
                          </div>
                          <p>Available <span>USDT</span></p>
                          <div>
                            <img src={require("../assets/images/icon_large_02.png")} alt="Icon" className="withdraw_usdt_icon" />
                            <h3>{(roi.available > 0) ? roi.available.toFixed(2) : 0}</h3>
                          </div>
                        </div>
                        <div className="withdraw_box_bottom">
                          <label>Enter the amount to withdraw</label>
                          <div>
                            <input
                              onChange={inputChange}
                              id="roi"
                              placeholder="Enter amount"
                              type="text"
                              className="primary_input"
                              value={gainwithdraw.roi}
                            />
                            <Button
                              disabled={(parseFloat(roi.available) > 0) ? false : (isLoad2 ? isLoad2 : false)}
                              onClick={() => getROI()}
                              className="primary_btn">Withdraw</Button>
                          </div>
                          {errors && errors.roi && errors.roi !== "" &&
                            <p style={{ color: 'red' }}>{errors.roi}</p>
                          }
                          {isLoad2 &&
                            <div class="form-group button_new_home banner">
                              <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading" />
                            </div>
                          }
                        </div>
                      </div>
                    </GridItem>
                    <GridItem md={6}>
                      <div className="withdraw_box">
                        <div className="withdraw_box_top">
                          <h2>Referral Earned</h2>
                          <p>Total USDT Earned</p>
                          <div>
                            <img src={require("../assets/images/icon_large_02.png")} alt="Icon" className="withdraw_usdt_icon" />
                            <h3>{(referal.total > 0) ? referal.total.toFixed(2) : 0}</h3>
                          </div>
                          <p>Available USDT</p>
                          <div>
                            <img src={require("../assets/images/icon_large_02.png")} alt="Icon" className="withdraw_usdt_icon" />
                            <h3>{(referal.available > 0) ? referal.available.toFixed(2) : 0}</h3>
                          </div>
                        </div>
                        <div className="withdraw_box_bottom">
                          <label>Enter the amount to withdraw</label>
                          <div>
                            <input
                              onChange={inputChange}
                              id="referal"
                              placeholder="Enter amount"
                              type="text"
                              className="primary_input"
                              value={gainwithdraw.referal}
                            />
                            <Button
                              disabled={(parseFloat(referal.available) > 0) ? false : (isLoad3 ? isLoad3 : false)}
                              onClick={() => getReferal()}
                              className="primary_btn">Withdraw</Button>
                          </div>
                          {errors && errors.referal && errors.referal !== "" &&
                            <p style={{ color: 'red' }}>{errors.referal}</p>
                          }
                          {isLoad3 &&
                            <div class="form-group button_new_home banner">
                              <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading" />
                            </div>
                          }
                        </div>
                      </div>
                    </GridItem>
                    <GridItem md={6}>
                      <div className="withdraw_box">
                        <div className="withdraw_box_top">
                          <h2>Level Income</h2>
                          <p>Total USDT Earned</p>
                          <div>
                            <img src={require("../assets/images/icon_large_02.png")} alt="Icon" className="withdraw_usdt_icon" />
                            <h3>{(levelincome.total > 0) ? levelincome.total.toFixed(2) : 0}</h3>
                          </div>
                          <p>Available USDT</p>
                          <div>
                            <img src={require("../assets/images/icon_large_02.png")} alt="Icon" className="withdraw_usdt_icon" />
                            <h3>{(levelincome.available > 0) ? levelincome.available.toFixed(2) : 0}</h3>
                          </div>
                        </div>
                        <div className="withdraw_box_bottom">
                          <label>Enter the amount to withdraw</label>
                          <div>
                            <input
                              onChange={inputChange}
                              id="levelincome"
                              placeholder="Enter amount"
                              type="text"
                              className="primary_input"
                              value={gainwithdraw.levelincome}
                            />
                            <Button
                              disabled={(parseFloat(levelincome.available) > 0) ? false : (isLoad4 ? isLoad4 : false)}
                              onClick={() => getLevelIncome()}
                              className="primary_btn">Withdraw</Button>
                          </div>
                          {errors && errors.levelincome && errors.levelincome !== "" &&
                            <p style={{ color: 'red' }}>{errors.levelincome}</p>
                          }
                          {isLoad4 &&
                            <div class="form-group button_new_home banner">
                              <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading" />
                            </div>
                          }
                        </div>
                      </div>
                    </GridItem>
                    <GridItem md={6}>
                      <div className="withdraw_box">
                        <div className="withdraw_box_top">
                          <h2>Level Income Passedup</h2>
                          <p>Total USDT Earned</p>
                          <div>
                            <img src={require("../assets/images/icon_large_02.png")} alt="Icon" className="withdraw_usdt_icon" />
                            <h3>{(passedup.total > 0) ? passedup.total.toFixed(2) : 0}</h3>
                          </div>
                          <p>Available USDT</p>
                          <div>
                            <img src={require("../assets/images/icon_large_02.png")} alt="Icon" className="withdraw_usdt_icon" />
                            <h3>{(passedup.available > 0) ? passedup.available.toFixed(2) : 0}</h3>
                          </div>
                        </div>
                        <div className="withdraw_box_bottom">
                          <label>Enter the amount to withdraw</label>
                          <div>
                            <input
                              onChange={inputChange}
                              id="passedup"
                              placeholder="Enter amount"
                              type="text"
                              className="primary_input"
                              value={gainwithdraw.passedup}
                            />
                            <Button
                              disabled={(parseFloat(passedup.available) > 0) ? false : (isLoad5 ? isLoad5 : false)}
                              onClick={() => getPassedupIncome()}
                              className="primary_btn">Withdraw</Button>
                          </div>
                          {errors && errors.passedup && errors.passedup !== "" &&
                            <p style={{ color: 'red' }}>{errors.passedup}</p>
                          }
                          {isLoad5 &&
                            <div class="form-group button_new_home banner">
                              <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading" />
                            </div>
                          }
                        </div>
                      </div>
                    </GridItem>

                    {/* <GridItem md={6}>
                      <div className="withdraw_box">
                        <div className="withdraw_box_top">
                          <h2>Gameon Earned</h2>
                          <p>Total Inorbit Token Earned</p>
                          <div>
                            <img src={require("../assets/images/icon_large_01.png")} alt="Icon" className="withdraw_inorbit_icon" />
                            <h3>{(gameon.total > 0) ? gameon.total.toFixed(2) : 0}</h3>
                          </div>
                          <p>Available Inorbit Token</p>
                          <div>
                            <img src={require("../assets/images/icon_large_01.png")} alt="Icon" className="withdraw_inorbit_icon" />
                            <h3>{(gameon.available > 0) ? gameon.available.toFixed(2) : 0}</h3>
                          </div>
                        </div>
                        <div className="withdraw_box_bottom withdraw_box_bottom_disabled">
                          <label>Enter the amount to withdraw</label>
                          <div>
                            <input
                              onChange={inputChange}
                              id="gameon"
                              placeholder="Enter amount"
                              type="text"
                              className="primary_input"
                              value={gainwithdraw.gameon}
                            />

                            <Button
                              disabled={(parseFloat(gameon.available) > 0) ? false : (isLoad6 ? isLoad6 : false)}
                              className="primary_btn"
                              onClick={() => getGameonIncome()}
                            >Withdraw</Button>
                          </div>
                          {errors && errors.gameon && errors.gameon !== "" &&
                            <p style={{ color: 'red' }}>{errors.gameon}</p>
                          }
                          {isLoad6 &&
                            <div class="form-group button_new_home banner">
                              <ReactLoading type={"spinningBubbles"} color={config.loaderColor} className="loading" />
                            </div>
                          }
                        </div>
                      </div>
                    </GridItem> */}

                    {/* <GridItem md={6}>
                      <div className="withdraw_box">
                        <div className="withdraw_box_top">
                          <h2>Private Token Sale</h2>
                          <p>Total Inorbit Token Earned</p>
                          <div>
                            <img src={require("../assets/images/icon_large_01.png")} alt="Icon" className="withdraw_inorbit_icon" />
                            <h3>{(privatesale.total > 0) ? privatesale.total.toFixed(2) : 0}</h3>
                          </div>
                          <p>Available Inorbit Token</p>
                          <div>
                            <img src={require("../assets/images/icon_large_01.png")} alt="Icon" className="withdraw_inorbit_icon" />
                            <h3>{(privatesale.available > 0) ? privatesale.available.toFixed(2) : 0}</h3>
                          </div>
                        </div>
                        <div className="withdraw_box_bottom withdraw_box_bottom_disabled">
                          <label>Enter the amount to withdraw</label>
                          <div>
                            <input type="text" className="primary_input" placeholder="Enter amount" />
                            <Button className="primary_btn">Withdraw</Button> 
                </div>
              </div>
            </div>
          </GridItem> */}

                  </GridContainer>
                </div>
              </div>
            </GridItem >
          </GridContainer >
        </div >
      </div >
      {txurl && txurl !== "" &&
        <WithdrawModal txurl={txurl} />
      }
      <Footer />
    </div >
  );
}