import axios from "axios";
import config from "../config/config";

const baseUrl = config.baseUrl;

export const checkRef = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/check-referal`,
            'data': data
        });
        return {
            user: respData.data.user,
            ref: respData.data.ref,
            status: respData.data.status
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const saveUser = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/register-user`,
            'data': data
        });
        return {
            message: respData.data.message,
            result: respData.data.result,
            token: respData.data.token,
            errors: respData.data.errors,
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}


export const activateUser = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/activate-account`,
            'data': data
        });
        return {
            message: respData.data.message
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const loginCheck = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/user-login`,
            'data': data
        });
        return {
            message: respData.data.message,
            errors: respData.data.errors,
            token: respData.data.token
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const getProfile = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-profile`,
            'data': data
        });
        return {
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const getVolume = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-total-volume`,
            'data': data
        });
        return {
            result: respData.data.result,
            lastregister: respData.data.lastregister
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const updateProfile = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/update-profile`,
            'data': data,
            'headers': {
                'authtoken': localStorage.getItem('accessToken')
            }
        });
        return {
            result: respData.data.result,
            errors: respData.data.errors,
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const updatePassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/change-password`,
            'data': data
        });
        return {
            result: respData.data.result,
            errors: respData.data.errors,
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const getNetwork = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-network`,
            'data': data,
            'headers': {
                'authtoken': localStorage.getItem('accessToken')
            }
        });
        return {
            result: respData.data.result,
            userid: respData.data.userid
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const updateGameon = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/save-gameon`,
            'data': data,
            'headers': {
                'authtoken': localStorage.getItem('accessToken')
            }
        });
        return {
            result: respData.data.result,
            errors: respData.data.errors,
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        return err.response.data.errors;
    }
    else {
        return '';
    }
}


