import React from 'react'
const SuccessModal = (props) => {


    var txurl = props.txurl;

    return (
        <div>
            <div id="Congratulations" class="modal fade" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header pt-0">
                            <button type="button" class="" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <div className="firt_text_section ">
                                <p>Successfully withdraw </p>
                                <p><a href={txurl} target="_blank">view</a></p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default SuccessModal;